import { useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { isEmptyObj } from "../../services/utils";
import { apiCall } from "../../services/api";
import Alert from "@mui/material/Alert";
import { validatePassword } from "../../services/userUtils";

const ChangePassword = () => {
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState(false);

  const isPasswordValid = validatePassword(password);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isPasswordValid) return;

    apiCall("post", "/api/auth/changePassword", { password })
      .then((response) => {
        console.log(response);
        setSuccess(true);
      })
      .catch((err) => {
        console.log("error inside change password", err);
      });
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  return (
    <Box component="span" sx={{ p: 10, m: 5 }}>
      <div className="row justify-content-md-center text-center">
        <div className="col-md-6">
          <form onSubmit={handleSubmit}>
            <Stack direction="column" alignItems="center" spacing={4}>
              <Typography variant="h5" gutterBottom>
                Change Password!
              </Typography>
              <Paper sx={{ p: 5 }} elevation={10}>
                <Stack direction="column" alignItems="center" spacing={2}>
                  <TextField
                    id="password"
                    name="password"
                    label="Password"
                    onChange={handlePasswordChange}
                    type="password"
                    required
                    sx={{ m: 2, width: "30ch" }}
                    value={password}
                    error={!isPasswordValid && password.length > 0}
                  />
                  <TextField
                    id="confirmPassword"
                    name="confirmPassword"
                    label="Confirm Password"
                    helperText={password !== confirmPassword && !isEmptyObj(confirmPassword) ? "Passwords do not match" : ""}
                    error={password !== confirmPassword && !isEmptyObj(confirmPassword)}
                    onChange={handleConfirmPasswordChange}
                    type="password"
                    required
                    sx={{ m: 2, width: "30ch" }}
                    value={confirmPassword}
                  />
                  {isEmptyObj(password) && (
                    <Typography sx={{ fontSize: "small" }} variant="body2" gutterBottom>
                      Enter a new password!!
                    </Typography>
                  )}
                  {!isEmptyObj(password) && !isPasswordValid && (
                    <Typography sx={{ fontSize: "small" }} variant="body2" gutterBottom color="error">
                      Password must have aleast 8 characters <br />
                      at least 1 uppercase letter and 1 lowercase letter <br />
                      at least 1 number, and 1 special character."
                    </Typography>
                  )}
                  {!isEmptyObj(password) && isPasswordValid && isEmptyObj(confirmPassword) && (
                    <Typography sx={{ fontSize: "small" }} variant="body2" gutterBottom>
                      Confirm your password!!
                    </Typography>
                  )}
                  {!isEmptyObj(password) && isPasswordValid && confirmPassword === password && (
                    <Typography sx={{ fontSize: "small" }} variant="body2" gutterBottom>
                      Click continue to reset your password!!
                    </Typography>
                  )}
                </Stack>
              </Paper>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Button variant="contained" color="error" component={Link} to={`/`} disabled={success}>
                  Cancel
                </Button>
                <Button
                  disabled={isEmptyObj(password) || isEmptyObj(confirmPassword) || password !== confirmPassword || !isPasswordValid || success}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Continue
                </Button>
              </Stack>
              {success && (
                <Alert
                  onClose={() => {
                    history.push("/");
                  }}
                >
                  Password updated successfully.You may proceed to login.
                </Alert>
              )}
            </Stack>
          </form>
        </div>
      </div>
    </Box>
  );
};

export default ChangePassword;

import React, { useState, useEffect } from "react";
import { getFieldsForRecordType } from "../../services/standardEntityFieldService";
import { transactionEntities, getPageTitle } from "../../services/standardEntityFieldService";
import { generatePDFContent } from "../../services/pdfContentService";
import { apiCall } from "../../services/api";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import withAuth from "../../hocs/withAuth";
import { Link } from "react-router-dom";
import _ from "lodash";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PDFGenerator from "../buttons/PdfGenerator";
import { FormViewField } from "../inputFields/FormViewField";
import { allowLocatorAccessControl, openingStockDate } from "../../constants/globalConstants";

//todo:fix this global constants structure to be better suited
let globalConstants = { openingStockDate, allowLocatorAccessControl };
function RecordFullViewForm({
  fields = [],
  formMinWidth = "37vw",
  formMinHeight = "82vh",
  entityType,
  isValidRecord = () => true,
  creationAllowed = true,
  selectedRecord,
  setSelectedRecord,
  isEditable = () => true,
  hasBackButton = () => true,
  currentUser,
  pageTitle = "",
  updateRedirectUrl = null,
}) {
  let { id, recordType }: any = useParams();
  console.log("Pawan id is", id);
  recordType = entityType ? entityType : recordType;
  if (_.isEmpty(fields)) {
    fields = getFieldsForRecordType(recordType);
  }
  let recordId = id ? id : selectedRecord.id;
  updateRedirectUrl = updateRedirectUrl ? updateRedirectUrl : `/${recordType}/update/${recordId}`;

  console.log("recordType is", recordType);

  if (_.isEmpty(pageTitle)) {
    pageTitle = getPageTitle(recordType);
  }

  console.log("id is", id);
  const [record, setRecord] = useState(selectedRecord);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (_.isEmpty(id)) {
      return;
    }
    setLoading(true);
    console.log("going to make request for id", id);
    apiCall("get", `/api/entity/${recordType}/${id}`)
      .then((response) => {
        console.log("record fetched is", response);

        if (response == null) {
          console.log("Null response for records fetch");
          return;
        }
        console.log("values for table input new record is", response);
        setRecord(response);
      })
      .catch((err) => {
        console.log("Error fetching records", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, recordType]);

  console.log("fields are", fields);
  //todo: govern this by global constants kind of structure
  if (recordType === "material-inward") {
    if (record && record["inventoryValue"] && record["basicValue"]) {
      let dividend = parseFloat(record["inventoryValue"]);
      let divisor = parseFloat(record["basicValue"]);
      if (!isNaN(dividend) && !isNaN(divisor) && divisor !== 0) {
        globalConstants["invByBasic"] = dividend / divisor;
      }
    }
  }
  return (
    <div>
      <Box sx={{ marginTop: 1.5, marginLeft: 0 }}>
        <form>
          <Stack direction="column" alignItems="center" spacing={2}>
            <Stack direction="row" alignItems="center" spacing={2} sx={{ ml: 0 }}>
              <h4 className="sub-header"> {pageTitle}</h4>
              {creationAllowed && (
                <Button
                  sx={{
                    width: "80px",
                    height: "40px",
                    fontSize: "0.8rem",
                  }}
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                  component={Link}
                  to={`/${recordType}/add/`}
                >
                  New
                </Button>
              )}
              {transactionEntities.includes(recordType) &&
                (loading || !record || record.id == null ? (
                  <Box sx={{ ml: 22, display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <PDFGenerator generateContent={() => generatePDFContent(fields, record, recordType)} record={record} />
                ))}
            </Stack>
            <Paper
              sx={{
                minWidth: formMinWidth,
                minHeight: formMinHeight,
                marginLeft: 0,
                backgroundColor: "#F7F6FF",
              }}
              className="flexPaper"
              elevation={10}
            >
              <Stack sx={{ mt: 4, mb: 4 }} direction="column" alignItems="center" spacing={2}>
                {loading || !record ? (
                  <Box sx={{ ml: 0, display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  fields.map((field) => FormViewField({ field, record, entityType: recordType, globalConstants }))
                )}
              </Stack>
            </Paper>
            <Stack direction="row" alignItems="center" spacing={2}>
              {!hasBackButton() ? (
                <></>
              ) : selectedRecord ? (
                <Button
                  variant="contained"
                  color="error"
                  // component={Link}
                  onClick={() => {
                    console.log("comes here to set selected record null");
                    setSelectedRecord(null);
                  }}
                  // to={`/${recordType}/view`}
                >
                  Back
                </Button>
              ) : (
                <Button variant="contained" color="error" component={Link} to={`/${recordType}/view`}>
                  Back
                </Button>
              )}
              {isEditable() && (
                <Button
                  variant="contained"
                  component={Link}
                  disabled={record?.cancelled || record?.delivered || record?.closed}
                  to={updateRedirectUrl}
                >
                  Edit
                </Button>
              )}
            </Stack>
          </Stack>
          <h2> </h2>
        </form>
      </Box>
    </div>
  );
}

export default withAuth(RecordFullViewForm);

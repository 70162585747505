// fieldUtils.js
import { getDefaultValuesForFields, getLeafFields } from "./fieldsUtils";

import {
  materialInwardFields,
  materialInwardColumns,
  materialInwardSearchColumns,
  materialInwardDetailFields,
  // materialInwardDetailColumns,
  materialInwardExcelColumns,
  MaterialInwardDetailExcelColumns,
} from "../standardEntities/materialInwardFields";

import {
  materialOutwardFields,
  materialOutwardColumns,
  materialOutwardDetailFields,
  materialOutwardSearchColumns,
  materialOutwardExcelColumns,
  materialOutwardDetailExcelColumns,
} from "../standardEntities/materialOutward";

import {
  gatePassFields,
  gatePassColumns,
  gatePassSearchColumns,
  gatePassExcelColumns,
  gatePassDetailExcelColumns,
} from "../standardEntities/gatePass";

import { stockViewColumns, stockSearchColumns, stockViewExcelColumns, stockViewDetailExcelColumns } from "../standardEntities/stockView";

import { stationFields, stationColumns, stationSearchColumns, stationExcelColumns } from "../standardEntities/station";

import { itemFields, itemColumns, itemSearchColumns, itemExcelColumns } from "../standardEntities/item";

import {
  companyFields,
  companyColumns,
  companySearchColumns,
  companyExcelColumns,
  companyGstFields,
  companyGstColumns,
  companyGstSearchColumns,
  companyGstExcelColumns,
} from "../standardEntities/company";

import { locatorFields, locatorColumns, locatorSearchColumns, locatorExcelColumns } from "../standardEntities/locator";

import { projectFields, projectColumns, projectSearchColumns, projectExcelColumns } from "../standardEntities/project";

import { itemGroupFields, itemGroupColumns, itemGroupSearchColumns, itemGroupExcelColumns } from "../standardEntities/itemGroup";

import { userFields, userColumns, userExcelColumns } from "../standardEntities/user";
import { jobFields, jobColumns, jobExcelColumns, jobSearchColumns } from "../standardEntities/job";
import {
  childWorkOrderFields,
  childWorkOrderColumns,
  childWorkOrderExcelColumns,
  childWorkOrderSearchColumns,
} from "../standardEntities/childWorkOrder";
import { workClosureFields, workClosureColumns, workClosureExcelColumns, workClosureSearchColumns } from "../standardEntities/workClosure";
import { cbSheetColumns, cbSheetExcelColumns, cbSheetDetailFields, cbSheetDetailExcelColumns, cbSheetFields, cbSheetSearchColumns } from "../standardEntities/cbSheetFields";

const fieldMap = new Map();
fieldMap.set("station", stationFields);
fieldMap.set("company", companyFields);
fieldMap.set("companyGst", companyGstFields);
fieldMap.set("locator", locatorFields);
fieldMap.set("item", itemFields);
fieldMap.set("itemGroup", itemGroupFields);
fieldMap.set("project", projectFields);
fieldMap.set("material-inward", materialInwardFields);
fieldMap.set("material-inward-dtl", materialInwardDetailFields);
fieldMap.set("material-outward", materialOutwardFields);
fieldMap.set("material-outward-dtl", materialOutwardDetailFields);
fieldMap.set("gate-pass", gatePassFields);
fieldMap.set("user", userFields);
fieldMap.set("job", jobFields);
fieldMap.set("child-work-order", childWorkOrderFields);
fieldMap.set("work-closure", workClosureFields);
fieldMap.set("cb-sheet", cbSheetFields);
fieldMap.set("cb-sheet-dtl", cbSheetDetailFields)

const columnsMap = new Map();
columnsMap.set("station", stationColumns);
columnsMap.set("company", companyColumns);
columnsMap.set("companyGst", companyGstColumns);
columnsMap.set("locator", locatorColumns);
columnsMap.set("item", itemColumns);
columnsMap.set("itemGroup", itemGroupColumns);
columnsMap.set("user", userColumns);
columnsMap.set("project", projectColumns);
columnsMap.set("material-inward", materialInwardColumns);
columnsMap.set("stock", stockViewColumns);
columnsMap.set("material-outward", materialOutwardColumns);
columnsMap.set("gate-pass", gatePassColumns);
columnsMap.set("job", jobColumns);
columnsMap.set("child-work-order", childWorkOrderColumns);
columnsMap.set("work-closure", workClosureColumns);
columnsMap.set("cb-sheet", cbSheetColumns);

const excelColumnsMap = new Map();
excelColumnsMap.set("station", stationExcelColumns);
excelColumnsMap.set("item", itemExcelColumns);
excelColumnsMap.set("itemGroup", itemGroupExcelColumns);
excelColumnsMap.set("company", companyExcelColumns);
excelColumnsMap.set("companyGst", companyGstExcelColumns);
excelColumnsMap.set("locator", locatorExcelColumns);
excelColumnsMap.set("user", userExcelColumns);
excelColumnsMap.set("project", projectExcelColumns);
excelColumnsMap.set("material-inward", materialInwardExcelColumns);
excelColumnsMap.set("material-inward-dtl", MaterialInwardDetailExcelColumns);
excelColumnsMap.set("stock", stockViewExcelColumns);
excelColumnsMap.set("stock-dtl", stockViewDetailExcelColumns);
excelColumnsMap.set("material-outward", materialOutwardExcelColumns);
excelColumnsMap.set("material-outward-dtl", materialOutwardDetailExcelColumns);
excelColumnsMap.set("gate-pass", gatePassExcelColumns);
excelColumnsMap.set("gate-pass-dtl", gatePassDetailExcelColumns);
excelColumnsMap.set("job", jobExcelColumns);
excelColumnsMap.set("child-work-order", childWorkOrderExcelColumns);
excelColumnsMap.set("work-closure", workClosureExcelColumns);
excelColumnsMap.set("cb-sheet", cbSheetExcelColumns);
excelColumnsMap.set("cb-sheet-dtl", cbSheetDetailExcelColumns);

const searchColumnsMap = new Map();
searchColumnsMap.set("station", stationSearchColumns);
searchColumnsMap.set("item", itemSearchColumns);
searchColumnsMap.set("itemGroup", itemGroupSearchColumns);
searchColumnsMap.set("company", companySearchColumns);
searchColumnsMap.set("companyGst", companyGstSearchColumns);
searchColumnsMap.set("locator", locatorSearchColumns);
searchColumnsMap.set("user", userColumns);
searchColumnsMap.set("project", projectSearchColumns);
searchColumnsMap.set("material-inward", materialInwardSearchColumns);
searchColumnsMap.set("material-inward-dtl", materialInwardDetailFields);
searchColumnsMap.set("stock", stockSearchColumns);
searchColumnsMap.set("stock-dtl", stockSearchColumns);
searchColumnsMap.set("material-outward", materialOutwardSearchColumns);
searchColumnsMap.set("gate-pass", gatePassSearchColumns);
searchColumnsMap.set("job", jobSearchColumns);
searchColumnsMap.set("child-work-order", childWorkOrderSearchColumns);
searchColumnsMap.set("work-closure", workClosureSearchColumns);
searchColumnsMap.set("cb-sheet", cbSheetSearchColumns);

export const transactionEntities = ["material-inward", "material-inward-dtl", "material-outward", "material-outward-dtl", "gate-pass", "cb-sheet", "work-closure"];

export const isTransactionEntity = (entityType) => {
  return transactionEntities.includes(entityType);
};

export const hasDetailEntity = (entityType) => {
  return (isTransactionEntity(entityType) && getDetailRecordType(entityType))
}
export const adminEntities = ["user"];
export const reportEntities = ["stock", "material-inward-dtl"];

export function getFieldsForRecordType(recordType) {
  if (fieldMap.has(recordType)) {
    return fieldMap.get(recordType);
  } else {
    return [];
  }
}

export function getDefaultValuesForRecordType(recordType) {
  let defaultValues = { id: null, cancelled: false };
  if (fieldMap.has(recordType)) {
    const fields = fieldMap.get(recordType);
    return getDefaultValuesForFields(fields);
  }
  return defaultValues;
}

export function getLeafFieldsForRecordType(recordType) {
  if (fieldMap.has(recordType)) {
    const fields = fieldMap.get(recordType);
    return getLeafFields(fields);
  }
  return [];
}

export function getColumnsForRecordType(recordType) {
  if (columnsMap.has(recordType)) {
    return columnsMap.get(recordType);
  } else {
    return [];
  }
}

export function getExcelColumnsForRecordType(recordType) {
  if (excelColumnsMap.has(recordType)) {
    return excelColumnsMap.get(recordType);
  } else {
    return [];
  }
}

export function getSearchColumnsForRecordType(recordType) {
  if (searchColumnsMap.has(recordType)) {
    return searchColumnsMap.get(recordType);
  } else {
    return [];
  }
}

export function getPageTitle(recordType: string): string {
  let pageTitle = recordType;
  switch (recordType) {
    case "station":
      pageTitle = "Station";
      break;
    case "itemGroup":
      pageTitle = "Item Group";
      break;
    case "item":
      pageTitle = "Item";
      break;
    case "company":
      pageTitle = "Company";
      break;
    case "companyGst":
      pageTitle = "Company GST";
      break;
    case "locator":
      pageTitle = "Locator";
      break;
    case "project":
      pageTitle = "Project";
      break;
    case "material-inward":
      pageTitle = "Material Inward Receipt";
      break;
    case "material-outward":
      pageTitle = "Material Outward Authorization";
      break;
    case "gate-pass":
      pageTitle = "Gate Pass";
      break;
    case "stock":
      pageTitle = "Material Stock";
      break;
    case "job":
      pageTitle = "Fibre Factory Job";
      break;
    case "child-work-order":
      pageTitle = "Child Work Order";
      break;
    case "work-closure":
      pageTitle = "Work Closure";
      break;
    case "cb-sheet":
      pageTitle = "Consumption Book Sheet";
      break;
  }
  return pageTitle;
}

export function getDetailRecordType(recordType: string): string {
  let detailRecordType = null;
  if (recordType === "material-inward") {
    detailRecordType = "material-inward-dtl";
  } else if (recordType === "material-outward") {
    detailRecordType = "material-outward-dtl";
  } else if (recordType === "gate-pass") {
    detailRecordType = "gate-pass-dtl";
  } else if (recordType === "cb-sheet") {
    detailRecordType = "cb-sheet-dtl";
  } else if (recordType === "stock") {
    detailRecordType = "stock-dtl";
  }
  return detailRecordType;
}

export enum UserType {
  STANDARD = 0,
  ADMIN = 1,
}

export interface User {
  id: number;
  name: string;
  userId: string;
  password: string;
  email: string;
  mobile: string;
  approved: boolean;
  userType: UserType;
  //0 indicates no access, 1 indicates read access, 2 indicates write access
  masterAccessType: number;
  transactionAccessType: number;
  locatorAccessConfig: any;
  superUser?: boolean;
  allowMultipleSessions?: boolean;
  approvedAt?: Date;
  approvedBy?: string;
}

import { formatDate } from "../services/dateUtils";
import { translatePicklistValue } from "../services/fieldsUtils";
import { materialTypeValues, inTransactionTypeValues } from "../standardEntities/materialInwardFields";
import { projectTypeValues } from "../standardEntities/project";

export const entityType = "stock";

export const stockViewColumns = [
  {
    name: "docLocatorCode",
    label: "Locator",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "text",
  },
  {
    name: "totalAvailableQuantity",
    label: "Available Qty",
    type: "text",
  },
  // {
  //   name: "totalAvailableAmount",
  //   label: "Available Amt",
  //   type: "text",
  // },
  {
    name: "totalPhysicalQuantity",
    label: "Physical Qty",
    type: "text",
  },
  // {
  //   name: "totalPhysicalAmount",
  //   label: "Physical Amt",
  //   type: "text",
  // },
];

export const stockSearchColumns = [
  {
    name: "materialInwardDtl.docLocatorCode",
    label: "Locator",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "materialInwardDtl.projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "$project.projectType$",
    label: "Project Type",
    type: "picklist",
    values: [
      { value: "FF", label: "FF  Work" },
      { value: "FTTH", label: "FTTH Work" },
    ],
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "docLocator.state.stateName",
    label: "State",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "materialInwardDtl.itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "transactionType",
    label: "Transaction Type",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: "PU", label: "Purchase" },
      { value: "OT", label: "Owner Site Transfer" },
      { value: "ET", label: "External Site Transfer" },
      { value: "MS", label: "Main Store Transfer" },
      { value: "CS", label: "Contractor Transfer" },
      { value: "OS", label: "Opening Stock" },
      { value: "MG", label: "Misc. Gain" },
      { value: "IS", label: "Internal Shift" },
    ],
  },
  {
    name: "materialType",
    label: "Material Type",
    type: "picklist",
    defaultValue: "",
    values: materialTypeValues,
  },
  {
    name: "submit",
    label: "Submit",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
];

export const stockViewExcelColumns = [
  {
    name: "docLocatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "contractorCode",
    label: "Contractor Code",
    type: "text",
  },
  {
    name: "contractorName",
    label: "Contractor Name",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "text",
  },
  {
    name: "totalAvailableQuantity",
    label: "Available Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalAvailableAmount",
    label: "Available Amt",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalPhysicalQuantity",
    label: "Physical Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalPhysicalAmount",
    label: "Physical Amt",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
];

export const stockViewDetailExcelColumns = [
  {
    name: "docLocatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "docLocatorName",
    label: "Inward Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "docContractorCode",
    label: "Contractor Code",
    type: "text",
  },
  {
    name: "docContractorName",
    label: "Contractor Name",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  {
    name: "mirId",
    label: "MIR No",
    type: "text",
  },
  {
    name: "docDate",
    label: "MIR Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "transactionType",
    label: "Trans Type",
    type: "text",
    format: (value) => inTransactionTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "materialType",
    label: "Mat Type",
    type: "text",
    format: (value) => materialTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "submit",
    label: "Submit",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "id",
    label: "MIR Row Id",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "text",
  },
  {
    name: "totalQuantity",
    label: "Inward Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalAmount",
    label: "Inward Amt",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "availableQuantity",
    label: "Available Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "availableAmount",
    label: "Available Amt",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "physicalQuantity",
    label: "Physical Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "physicalAmount",
    label: "Physical Amt",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
];

import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

type colorType = "primary" | "secondary" | "error" | "success" | "warning" | "info";

export default function ConfirmationButtonComponent({
  handleSubmit,
  confirmationTitle,
  confirmationBody,
  submitBody,
  buttonText,
  color = "primary" as colorType,
  disabled = false,
  shouldOpen = (input) => {
    return true;
  },
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = (e) => {
    e.preventDefault();
    if (!shouldOpen(submitBody)) {
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleContinue = () => {
    setOpen(false);
    handleSubmit(submitBody);
  };

  return (
    <React.Fragment>
      <Button variant="contained" type="submit" disabled={disabled} onClick={handleClickOpen} color={color}>
        {buttonText}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{confirmationTitle}</DialogTitle>
        <DialogContent dividers>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{confirmationBody}</DialogContentText>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleContinue} autoFocus disabled={disabled}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

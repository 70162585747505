import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Link, withRouter } from "react-router-dom";
import { logout, authUser } from "../store/actions/auth";
import { connect } from "react-redux";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogOutComponent from "../components/buttons/LogOutComponent";
import LogoutIcon from "@mui/icons-material/Logout";
import { Collapse } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import NearMeIcon from "@mui/icons-material/NearMe";
import BusinessIcon from "@mui/icons-material/Business";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InventoryIcon from "@mui/icons-material/Inventory";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SummarizeIcon from "@mui/icons-material/Summarize";
import WorkIcon from "@mui/icons-material/Work";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ConditionalAddCircleButton from "../components/buttons/ConditionalAddCircleButton";
import { isAdminUser } from "../services/utils";

const drawerWidth = "14vw";

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  backgroundColor: "#1976d2",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

// const DrawerFooter = styled('div')(({ theme }) => ({
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'flex-end',
//     padding: theme.spacing(0, 1),
//     backgroundColor:'silver',
//     // necessary for content to be below app bar
//     ...theme.mixins.toolbar,
//   }));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function SideDrawer({ logout, currentUser, authUser }) {
  // const theme = useTheme();
  // const [open, setOpen] = React.useState(false);
  let open = true;
  const [openAdmin, setOpenAdmin] = useState(false);
  const [openCompany, setOpenCompany] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [openWork, setOpenWork] = useState(false);
  const [openStation, setOpenStation] = useState(false);
  const [openItem, setOpenItem] = useState(false);
  const [openReports, setOpenReports] = useState(false);
  const [openTransactions, setOpenTransactions] = useState(false);
  const history = useHistory();

  // const handleDrawerToggle = () => {
  //   setOpen(prevOpen=> !prevOpen);
  // };

  let title = "CIMS APP";

  if (currentUser == null || !currentUser.isAuthenticated) {
    return <></>;
  }

  console.log("currentUser in sidedrawer", currentUser);
  let user = currentUser.user;

  const handleMainBarClick = () => {
    setOpenAdmin(false);
    setOpenLocation(false);
    setOpenCompany(false);
    setOpenWork(false);
    setOpenStation(false);
    setOpenItem(false);
    setOpenReports(false);
    setOpenTransactions(false);
    history.push(`/`);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <ListItem onClick={handleMainBarClick} key={title} disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 2 : "auto",
                justifyContent: "center",
              }}
            >
              <WarehouseIcon sx={{ color: "#f0f0f0" }} />
            </ListItemIcon>
            <ListItemText primary={title} sx={{ opacity: open ? 1 : 0, color: "#f0f0f0" }} />
          </ListItemButton>
        </ListItem>
      </DrawerHeader>
      <Divider />
      <List>
        {hasMasterReadAccess() && (
          <>
            <ListItem button onClick={() => setOpenStation(!openStation)}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <NearMeIcon />
              </ListItemIcon>
              <ListItemText primary="Station" />
              {openStation ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </ListItem>
            <Collapse in={openStation} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/station/view">
                  <ListItemText primary="Stations" />
                  <ConditionalAddCircleButton to="/station/add" condition={hasMasterWriteAccess()} />
                </ListItem>
              </List>
            </Collapse>
            <ListItem button onClick={() => setOpenItem(!openItem)}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <InventoryIcon />
              </ListItemIcon>
              <ListItemText primary="Item" />
              {openItem ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </ListItem>
            <Collapse in={openItem} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/itemGroup/view">
                  <ListItemText primary="Item Group" />
                  <ConditionalAddCircleButton to="/itemGroup/add" condition={hasMasterWriteAccess()} />
                </ListItem>
                <ListItem button component={Link} to="/item/view">
                  <ListItemText primary="Items" />
                  <ConditionalAddCircleButton to="/item/add" condition={hasMasterWriteAccess()} />
                </ListItem>
              </List>
            </Collapse>
            <ListItem button onClick={() => setOpenCompany(!openCompany)}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary="Company" />
              {openCompany ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </ListItem>
            <Collapse in={openCompany} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/company/view">
                  <ListItemText primary="Companies" />
                  <ConditionalAddCircleButton to="/company/add" condition={hasMasterWriteAccess()} />
                </ListItem>
                <ListItem button component={Link} to="/companyGst/view">
                  <ListItemText primary="Company GST" />
                  <ConditionalAddCircleButton to="/companyGst/add" condition={hasMasterWriteAccess()} />
                </ListItem>
              </List>
            </Collapse>
            <ListItem button onClick={() => setOpenLocation(!openLocation)}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <LocationOnIcon />
              </ListItemIcon>
              <ListItemText primary="Location" />
              {openLocation ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </ListItem>
            <Collapse in={openLocation} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/locator/view">
                  <ListItemText primary="Locators" />
                  <ConditionalAddCircleButton to="/locator/add" condition={hasMasterWriteAccess()} />
                </ListItem>
              </List>
            </Collapse>
            
            <ListItem button onClick={() => setOpenWork(!openWork)}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <WorkIcon />
              </ListItemIcon>
              <ListItemText primary="Work" />
              {openWork ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </ListItem>
            <Collapse in={openWork} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
              <ListItem button component={Link} to="/project/view">
                  <ListItemText primary="Projects" />
                  <ConditionalAddCircleButton to="/project/add" condition={hasMasterWriteAccess()} />
                </ListItem>
                <ListItem button component={Link} to="/job/view">
                  <ListItemText primary="Fibre Factory Jobs" />
                  <ConditionalAddCircleButton to="/job/add" condition={hasMasterWriteAccess()} />
                </ListItem>
                <ListItem button component={Link} to="/child-work-order/view">
                  <ListItemText primary="Child Work Order" />
                  <ConditionalAddCircleButton to="/child-work-order/add" condition={hasMasterWriteAccess()} />
                </ListItem>
              </List>
            </Collapse>
          </>
        )}
        {hasTransactionReadAccess() && (
          <>
            <ListItem button onClick={() => setOpenTransactions(!openTransactions)}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <ReceiptLongIcon />
              </ListItemIcon>
              <ListItemText primary="Transactions" />
              {openTransactions ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </ListItem>
            <Collapse in={openTransactions} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/material-inward/view">
                  <ListItemText primary="Material Inward" />
                  <ConditionalAddCircleButton to="/material-inward/add" condition={hasTransactionWriteAcess()} />
                </ListItem>
                <ListItem button component={Link} to="/material-outward/view">
                  <ListItemText primary="Material Outward" />
                  <ConditionalAddCircleButton to="/material-outward/add" condition={hasTransactionWriteAcess()} />
                </ListItem>
                <ListItem button component={Link} to="/gate-pass/view">
                  <ListItemText primary="Gate Pass" />
                  <ConditionalAddCircleButton to="/gate-pass/add" condition={hasTransactionWriteAcess()} />
                </ListItem>
                <ListItem button component={Link} to="/cb-sheet/view">
                  <ListItemText primary="Consumption Book" />
                  <ConditionalAddCircleButton to="/cb-sheet/add" condition={hasTransactionWriteAcess()} />
                </ListItem>
                <ListItem button component={Link} to="/work-closure/view">
                  <ListItemText primary="Work Closure" />
                  <ConditionalAddCircleButton to="/work-closure/add" condition={hasTransactionWriteAcess()} />
                </ListItem>
              </List>
            </Collapse>
            <ListItem button onClick={() => setOpenReports(!openReports)}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <SummarizeIcon />
              </ListItemIcon>
              <ListItemText primary="Reports" />
              {openReports ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </ListItem>
            <Collapse in={openReports} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton component={Link} to="/report/stock">
                  <ListItemText primary="Stock Listing" />
                </ListItemButton>
                <ListItemButton component={Link} to="/report/stock-ageing">
                  <ListItemText primary="Stock Ageing" />
                </ListItemButton>
                <ListItemButton component={Link} to="/report/stock-opening-closing">
                  <ListItemText primary="Stock Open-Close" />
                </ListItemButton>
                <ListItemButton component={Link} to="/report/stock-ledger">
                  <ListItemText primary="Stock Ledger" />
                </ListItemButton>
                <ListItemButton component={Link} to="/report/stock-in-out">
                  <ListItemText primary="Stock In-Out" />
                </ListItemButton>
              </List>
            </Collapse>
          </>
        )}
      </List>
      {isAdminUser && isAdminUser(user) && (
        <>
          <Divider />
          <List>
            <ListItem button onClick={() => setOpenAdmin(!openAdmin)}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Admin" />
              {openAdmin ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </ListItem>

            <Collapse in={openAdmin} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/user/view">
                  <ListItemText primary="Users" />
                </ListItem>
              </List>
            </Collapse>
          </List>
          <Divider />
        </>
      )}
      <Divider />
      <List component="div" disablePadding>
        <ListItem button component={Link} to={`/my-profile/view`}>
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary="My Profile" />
        </ListItem>
      </List>
      <Divider />
      <div style={{ flexGrow: 1 }} />
      {open && (
        <>
          <List>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: "#1976d2",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <LogoutIcon sx={{ color: "#f0f0f0" }} />
              </ListItemIcon>
              {/* <ListItemText primary={"Log Out"} sx={{ opacity: open ? 1 : 0 }} /> */}
              <LogOutComponent onAuth={authUser} logout={logout} opacity={open ? 1 : 0} currentUser={currentUser.user} />
            </ListItemButton>
          </List>
          <Divider />
        </>
      )}
    </Drawer>
  );

  function hasMasterReadAccess() {
    return user.masterAccessType > 0;
  }

  function hasMasterWriteAccess() {
    return user.masterAccessType > 1;
  }

  function hasTransactionReadAccess() {
    return user.transactionAccessType > 0;
  }

  function hasTransactionWriteAcess() {
    return user.transactionAccessType > 1;
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  };
}

export default withRouter(connect(mapStateToProps, { logout, authUser })(SideDrawer));

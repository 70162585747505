export const timeZone = "Asia/Kolkata";
export const dateFormat = "dddd, DD-MM-YYYY HH:mm";
export const userTypesWithLables = [
  { value: 0, label: "Standard" },
  { value: 1, label: "Admin" },
];

const OPENING_STOCK_DATE = "2024-09-15";
export const openingStockDate = new Date(OPENING_STOCK_DATE);
export const allowLocatorAccessControl = false;

import React, { useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { isEmptyObj } from "../../services/utils";
import { useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

const VerifyTFAForm = ({ onAuth, currentUser }) => {
  const history = useHistory();
  const [userPin, setUserPin] = useState("");
  const [formError, setFormError] = useState("");
  const [rememberBrowser, setRememberBrowser] = useState(false); // Track the checkbox state

  let { userId } = useParams<{ userId: string }>();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Include the `rememberBrowser` value in the submission
      let result = await onAuth("verifyTFA", { userPin, rememberBrowser });
      if (result.validExistingSession) {
        history.push("/confirmLogin");
      } else {
        history.push("/");
      }
    } catch (e) {
      setFormError(e.message);
      return;
    }
  };

  const handleChange = (e) => {
    setUserPin(e.target.value);
    setFormError("");
  };

  const handleCheckboxChange = (e) => {
    setRememberBrowser(e.target.checked);
  };

  return (
    <Box component="span" sx={{ p: 10, m: 5 }}>
      <div className="row justify-content-md-center text-center">
        <div className="col-md-6">
          <form onSubmit={handleSubmit}>
            <Stack direction="column" alignItems="center" spacing={4}>
              <Typography variant="h5" gutterBottom>
                Two Factor Verification
              </Typography>
              <Paper sx={{ p: 5 }} elevation={10}>
                <Stack direction="column" alignItems="center" spacing={2}>
                  {userId && (
                    <TextField
                      id="userId"
                      name="userId"
                      label="User Id"
                      disabled={true}
                      type="text"
                      required
                      sx={{ m: 2, width: "30ch" }}
                      value={userId}
                    />
                  )}
                  <TextField
                    id="userPin"
                    name="userPin"
                    label="Verification Code"
                    onChange={handleChange}
                    error={!isEmptyObj(formError)}
                    helperText={formError ? formError : null}
                    type="text"
                    required
                    sx={{ m: 2, width: "30ch" }}
                    value={userPin}
                  />
                  <Typography sx={{ fontSize: "small" }} variant="body2" gutterBottom>
                    Enter the code sent to your registered email
                  </Typography>

                  {/* Add the Checkbox with a tooltip */}
                  <FormControlLabel
                    control={<Checkbox checked={rememberBrowser} onChange={handleCheckboxChange} color="primary" />}
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Remember this browser for 30 days
                        <Tooltip
                          title="After verification, you won't need to enter a Two-Factor Authentication code on this browser for 30 days."
                          arrow
                        >
                          <InfoIcon sx={{ ml: 1, cursor: "pointer" }} fontSize="small" />
                        </Tooltip>
                      </div>
                    }
                  />
                </Stack>
              </Paper>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Button variant="contained" color="error" component={Link} to={`/`}>
                  Cancel
                </Button>
                <Button disabled={isEmptyObj(userPin)} variant="contained" type="submit">
                  Submit
                </Button>
              </Stack>
            </Stack>
          </form>
        </div>
      </div>
    </Box>
  );
};

export default VerifyTFAForm;

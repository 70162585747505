import { useState, useEffect } from "react";
import RecordViewForm from "../recordStandardForms/RecordViewForm";
import withAuth from "../../hocs/withAuth";
import RecordFullViewForm from "../recordStandardForms/RecordFullViewForm";
import { useLocation } from "react-router-dom";
import { userColumns } from "../../standardEntities/user";
import { userSearchFields } from "../../standardEntities/user";
import { User } from "./UserTypes";
import { isAdminUser } from "../../services/utils";

const getDefaultRecordSearchRequest = () => {
  return { name: "", userId: "", email: "" };
};

function UserViewForm({ currentUser }) {
  const location = useLocation();
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [recordSearchRequest, setRecordSeachRequest] = useState(getDefaultRecordSearchRequest());

  useEffect(() => {
    setSelectedRecord(null);
  }, [location.pathname, currentUser]);

  const setSelectedRecordIfAllowed = (record: User) => {
    //we set the record only if our current user is allowed to view and edit a user
    if (isAdminUser(currentUser) || currentUser.superUser) {
      setSelectedRecord(record);
    }
  };

  return (
    <>
      {selectedRecord && (
        <RecordFullViewForm
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          creationAllowed={false}
          entityType="user"
          pageTitle={"User"}
          isEditable={() => isAdminUser(currentUser) || currentUser.superUser}
          currentUser={currentUser}
        />
      )}
      {!selectedRecord && (
        <RecordViewForm
          columns={userColumns}
          pageTitle="Users"
          creationAllowed={false}
          recordSearchRequest={recordSearchRequest}
          setRecordSeachRequest={setRecordSeachRequest}
          setSelectedRecord={setSelectedRecordIfAllowed}
          searchFields={userSearchFields}
          getDefaultRecordSearchRequest={getDefaultRecordSearchRequest}
          entityType="user"
        />
      )}
    </>
  );
}

export default withAuth(UserViewForm);

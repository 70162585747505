import { translatePicklistValue } from "../services/fieldsUtils";
import { Field } from "../types/field";
export const locatorTypeValues = [
  { value: "MS", label: "Main Store" },
  { value: "CS", label: "Contractor Store" },
  { value: "OT", label: "Owner Site" },
  { value: "ET", label: "External Site" },
];

// locatorFields.js
export const locatorFields: Field[] = [
  {
    name: "locatorName",
    label: "Locator Name",
    allowUpdate: true,
    type: "text",
    props: {
      width: "21vw",
    },
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "locatorType",
        label: "Locator Type",
        type: "picklist",
        props: {
          width: "10vw",
        },
        values: locatorTypeValues,
        defaultValue: "MS",
        linkedFields: [
          {
            name: "contractorName",
            value: null,
          },
        ],
      },
      {
        name: "locatorCode",
        label: "Locator Code",
        type: "text",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "10vw",
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "companyName",
        label: "Company Name",
        type: "lookup",
        lookupType: "company",
        lookupCategory: "locatorCompany",
        lookupParams: [
          {
            key: "locatorType",
            resolveValue: true,
            valueFieldName: "locatorType",
          },
        ],
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "companyCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "foreignFlg",
            fieldName: "foreignFlg",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "gstFlg",
            fieldName: "gstFlg",
          },
        ],
        props: {
          width: "14vw",
        },
        linkedFields: [
          {
            name: "stationName",
            value: null,
          },
          {
            name: "gstNo",
            value: null,
          },
        ],
      },
      {
        name: "companyCode",
        label: "Company Code",
        type: "text",
        props: {
          width: "6vw",
        },
        autoFilled: true,
        allowNull: true,
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    visibility: {
      type: "IN",
      field: "locatorType",
      values: ["CS"],
    },
    childFields: [
      {
        name: "contractorCode",
        label: "Contractor Code",
        type: "text",
        props: {
          width: "6vw",
        },
        autoFilled: true,
        allowNull: true,
      },
      {
        name: "contractorName",
        label: "Contractor Name",
        type: "lookup",
        lookupType: "company",
        lookupFilter: { type: "AND", filters: [{ type: "IN", field: "contractorFlg", values: [true] }] },
        props: {
          width: "14vw",
        },
        lookupParams: [
          {
            key: "locatorType",
            resolveValue: true,
            valueFieldName: "locatorType",
          },
        ],
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "contractorCode",
          },
        ],
        disability: {
          type: "NIN",
          field: "locatorType",
          values: ["CS"],
        },
      },
    ],
  },
  {
    name: "address1",
    label: "Address1",
    allowUpdate: true,
    type: "text",
    props: {
      width: "21vw",
    },
  },
  {
    name: "address2",
    label: "Address2",
    allowUpdate: true,
    type: "text",
    defaultValue: "",
    allowNull: true,
    props: {
      width: "21vw",
    },
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "stationName",
        label: "Station Name",
        allowUpdate: true,
        type: "lookup",
        lookupType: "station",
        props: {
          width: "14vw",
        },
        lookupFilter: {
          type: "EQUALS",
          field: "foreignFlg",
          resolveValue: true,
          valueFieldName: "foreignFlg",
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "stationCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "gstCode",
            fieldName: "gstCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "stateName",
            fieldName: "stateName",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "stateCode",
            fieldName: "stateCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "country",
            fieldName: "country",
          },
        ],
        disability: {
          type: "MISSING",
          field: "companyName",
        },
      },
      {
        name: "stationCode",
        label: "Station Code",
        type: "text",
        props: {
          width: "6vw",
        },
        autoFilled: true,
        allowNull: true,
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "stateName",
        label: "State Name",
        type: "text",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "10vw",
        },
      },
      {
        name: "country",
        label: "Country",
        type: "text",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "10vw",
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "gstNo",
        label: "GST Number",
        type: "lookup",
        lookupType: "companyGst",
        lookupCategory: "locatorCompany",
        defaultValue: null,
        props: {
          width: "14vw",
        },
        disability: {
          type: "OR",
          filters: [
            { type: "IN", field: "foreignFlg", values: [true] },
            { type: "IN", field: "gstFlg", values: [false] },
            { type: "MISSING", field: "companyName" },
          ],
        },
        lookupFilter: {
          field: "companyCode",
          type: "EQUALS",
          resolveValue: true,
          valueFieldName: "companyCode",
        },
      },
      {
        name: "gstCode",
        label: "GST Code",
        type: "text",
        props: {
          width: "6vw",
        },
        autoFilled: true,
        allowNull: true,
      },
    ],
  },
  {
    name: "ownerEmailId",
    label: "Email Id",
    type: "text",
    allowUpdate: true,
    allowNull: true,
    props: {
      width: "21vw",
    },
  },
  {
    name: "olocatorName",
    label: "Old Locator Name",
    type: "text",
    autoFilled: true,
    allowNull: true,
    props: {
      width: "21vw",
    },
  },
  {
    name: "section2",
    type: "formSection",
    childFields: [
      {
        name: "foreignFlg",
        label: "Foreign",
        type: "checkbox",
        autoFilled: true,
        defaultValue: false,
      },
      {
        name: "active",
        label: "Active",
        type: "checkbox",
        allowUpdate: true,
        defaultValue: false,
      },
    ],
  },
];

export const locatorColumns = [
  {
    name: "locatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "locatorType",
    label: "Type",
    type: "text",
    format: (value) => translatePicklistValue(value, locatorTypeValues),
  },
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
  },
  {
    name: "stationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "contractorName",
    label: "Contractor",
    type: "text",
  },
  {
    name: "foreignFlg",
    label: "Foreign",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const locatorExcelColumns = [
  {
    name: "locatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "locatorType",
    label: "Type",
    type: "text",
    format: (value) => translatePicklistValue(value, locatorTypeValues),
  },
  {
    name: "companyCode",
    label: "Company Code",
    type: "text",
  },
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
  },
  {
    name: "address1",
    label: "Address1",
    type: "text",
  },
  {
    name: "address2",
    label: "Address2",
    type: "text",
  },
  {
    name: "stationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "country",
    label: "Country",
    type: "text",
  },
  {
    name: "contractorCode",
    label: "Contractor Code",
    type: "text",
  },
  {
    name: "contractorName",
    label: "Contractor Name",
    type: "text",
  },
  {
    name: "gstNo",
    label: "GST Number",
    type: "text",
  },
  {
    name: "ownerEmailId",
    label: "Email Id",
    type: "text",
  },
  {
    name: "foreignFlg",
    label: "Foreign",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "id",
    label: "Locator Id",
    type: "text",
  },
];

export const locatorSearchColumns = [
  {
    name: "locatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator",
    type: "text",
  },
  {
    name: "locatorType",
    label: "Type",
    type: "picklist",
    values: locatorTypeValues,
  },
  {
    name: "company.companyName",
    label: "Company",
    type: "text",
  },
  {
    name: "stationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "contractor.companyName",
    label: "Contractor",
    type: "text",
  },
  {
    name: "foreignFlg",
    label: "Foreign",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
  {
    name: "active",
    label: "Active",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
];

import dayjs from "../config/dayjsConfig";
import _ from "lodash";
import { formatDate } from "./dateUtils";
import { Field } from "../types/field";
import { Filter } from "../types/filter";
import { Column } from "../types/column";
import { isEmptyObj } from "./utils";

export function getLeafFields(fields: Field[]): Field[] {
  let leafFields = [];
  fields.forEach((field) => {
    if (field.type === "formSection") {
      let childFields: Field[] = field.childFields;
      if (childFields) {
        childFields
          .filter((childField) => !childField.parentConditionsAppended)
          .forEach((childField) => {
            if (field.disability && !childField) {
              let newDisability: Filter = childField.disability
                ? { type: "OR", filters: [{ ...field.disability }, { ...childField.disability }] }
                : field.disability;
              childField.disability = { ...newDisability };
            }
            if (field.visibility) {
              let newVisibility: Filter = childField.visibility
                ? { type: "OR", filters: [{ ...field.visibility }, { ...childField.visibility }] }
                : field.visibility;
              childField.visibility = { ...newVisibility };
            }
            childField.parentConditionsAppended = true;
          });
      }
      let childLeafFields: Field[] = getLeafFields(field.childFields);

      leafFields.push(...childLeafFields);
    } else {
      leafFields.push(field);
    }
  });
  return leafFields;
}
export function getFieldNameToFieldMap(fields) {
  let fieldNameToFieldMap = {};
  let leafFields = getLeafFields(fields);
  leafFields.forEach((field) => {
    fieldNameToFieldMap[field.name] = field;
  });
  return fieldNameToFieldMap;
}

export function getDefaultValuesForFields(fields) {
  let defaultValues = {};
  fields.forEach((field) => {
    if (field.type === "formSection") {
      defaultValues = {
        ...defaultValues,
        ...getDefaultValuesForFields(field.childFields),
      };
    }

    if (field.defaultValue !== null && field.defaultValue !== undefined) {
      defaultValues[field.name] = field.defaultValue;
    } else if (field.type === "checkbox") {
      defaultValues[field.name] = false;
    } else if (field.type === "textArea") {
      defaultValues[field.name] = "";
    }
  });
  return defaultValues;
}

export const getNewValueForField = (type, checked, value) => {
  let newValue: any = "";
  if (type === "checkbox") {
    // Handle checkbox inputs (convert 'on' to true and 'off' to false)
    newValue = checked;
  } else if (type === "formTable") {
    newValue = !_.isEmpty(value) ? [...value] : value;
  } else if (type === "date") {
    let finalValue = value;
    try {
      finalValue = value.toISOString();
      newValue = dayjs(finalValue);
    } catch (e) {
      newValue = null;
    }
  } else if (type === "multipicklist") {
    value = typeof value === "string" ? value.split(",") : value;
  } else {
    // Handle other inputs
    newValue = value;
  }
  return newValue;
};

export const translatePicklistValue = (value, picklistValues) => {
  if (isEmptyObj(value)) {
    return "";
  }
  const option = picklistValues.find((item) => item.value === value);
  return option ? option.label : "Unknown";
};

export const formatValueForDisplay = (value, column: Column) => {
  if (isEmptyObj(value)) {
    return "";
  }

  switch (column.type) {
    case "date": {
      return formatDate(value);
    }
    case "checkbox": {
      return value ? "True" : "False";
    }
    case "picklist": {
      return translatePicklistValue(value, column.values);
    }
    case "number": {
      return !isEmptyObj(value) ? parseFloat(value) : 0;
    }
    case "text": {
      return column.format ? column.format(value) : value;
    }
    default:
      return value;
  }
};

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// Extend dayjs with the plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// Optionally, set a default timezone (if needed)
const defaultTimeZone = "Asia/Kolkata";
dayjs.tz.setDefault(defaultTimeZone);

// Export the configured instance
export default dayjs;

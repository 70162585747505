import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function LogOutComponent({
  logout,
  opacity = 1,
  onAuth,
  currentUser,
}) {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleContinue = async () => {
    setOpen(false);
    try {
      await onAuth("logout", currentUser);
    } catch (err) {
      console.log("Logout api call failed", err);
    }
    logout();
    history.push("/");
  };

  return (
    <React.Fragment>
      <Button
        variant="filled"
        style={{
          fontWeight: "normal",
          fontSize: "0.8rem",
          opacity,
          color: "#f0f0f0",
        }}
        onClick={handleClickOpen}
      >
        Log Out
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Log Out"}</DialogTitle>
        <DialogContent dividers>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are You Sure You Want To Proceed?
            </DialogContentText>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleContinue} autoFocus>
            Log Out
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

import withAuth from "../../../hocs/withAuth";
import _ from "lodash";
import RecordAdditionForm from "../../recordStandardForms/RecordAdditionForm";

function MaterialInwardForm() {
  const isValidRecord = (record, setFormErrors, setError) => {
    if (!record) {
      return false;
    }
    const totalDetailValues = _.isEmpty(record.details)
      ? 0
      : record.details
          .filter((detail) => !detail.deleted)
          .reduce((total, detail) => {
            return total + parseFloat(detail.basicAmount);
          }, 0);
    let isTotalValid = totalDetailValues === parseFloat(record.basicValue);
    if (!isTotalValid) {
      setFormErrors((formError) => {
        formError["basicValue"] = `Should match sum of all Basic Amounts ${totalDetailValues}`;
        return { ...formError };
      });
      setError(`Basic value should match sum of all Basic Amounts in detail ${totalDetailValues}`);
      return false;
    } else {
      setFormErrors((formError) => {
        formError["inventoryValue"] = "";
        return { ...formError };
      });
      setError("");
    }
    return true;
  };

  return (
    <RecordAdditionForm
      formMinWidth={"80vw"}
      entityType="material-inward"
      isValidEntityRecord={isValidRecord}
      allowDelete={false}
      formDisabled={(record) => record && (record.transactionType === "IS" || record.transactionType === "MS" || record.transactionType === "CS")}
    />
  );
}

export default withAuth(MaterialInwardForm);

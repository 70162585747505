import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { apiCall } from "../../services/api";
import { generatePDF, generatePDFContent } from "../../services/pdfContentService";
import { getFieldsForRecordType } from "../../services/standardEntityFieldService";

function OpenView() {
  const location = useLocation();
  const history = useHistory();

  // Extract the query parameters from the URL
  const searchParams = new URLSearchParams(location.search);
  const key = searchParams.get("key");
  const token = searchParams.get("token");

  const generatePdfContentHelper = (response): any => {
    let { entityType } = response;
    return generatePDFContent(getFieldsForRecordType(entityType), response, entityType);
  };

  useEffect(() => {
    if (key && token) {
      // Make API call with key and token
      apiCall("get", `/api/open/fetchEntityUsingUrl?key=${key}&token=${token}`)
        .then((response) => {
          console.log("response is", response);
          if (!response) {
            history.push("/");
            return;
          }
          generatePDF(() => generatePdfContentHelper(response), true);
        })
        .catch((error) => {
          console.error("Error fetching entity:", error);
          // Redirect to login screen or handle the error in a different way
          history.push("/");
        });
    }
  }, [key, token, history]);

  return (
    <Box sx={{ ml: 0, display: "flex" }}>
      <CircularProgress />
    </Box>
  );
}

export default OpenView;

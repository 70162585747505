import { Field } from "../types/field";
export const itemGroupFields: Field[] = [
  {
    name: "name",
    label: "Item Group Name",
    type: "text",
  },
  {
    name: "id",
    label: "Item Group Id",
    type: "number",
    autoFilled: true,
    allowNull: true,
  },
  {
    name: "active",
    label: "Active",
    type: "checkbox",
    allowUpdate: true,
    defaultValue: false,
  },
];

export const itemGroupColumns = [
  {
    name: "name",
    label: "Item Group Name",
    type: "text",
  },
  {
    name: "id",
    label: "Item Group Id",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const itemGroupExcelColumns = [
  {
    name: "name",
    label: "Item Group Name",
    type: "text",
  },
  {
    name: "id",
    label: "Item Group Id",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const itemGroupSearchColumns = [
  {
    name: "itemGroupName",
    label: "Item Group Name",
    type: "text",
    props: {
      widthFactor: 2,
    },
  },
  {
    name: "itemGroupId",
    label: "Item Group Id",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
];

import React from "react";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Link } from "react-router-dom";

const ConditionalAddCircleButton = ({ to, condition }) => {
  if (!condition) {
    return null;
  }

  return (
    <IconButton component={Link} to={to}>
      <AddCircleIcon fontSize="small" />
    </IconButton>
  );
};

export default ConditionalAddCircleButton;

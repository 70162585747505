import React, { useState, useEffect } from "react";
import RecordFullViewForm from "./RecordFullViewForm";
import RecordViewForm from "./RecordViewForm";
import { useParams, useLocation, useHistory } from "react-router-dom";
import _ from "lodash";
import { getSearchColumnsForRecordType } from "../../services/standardEntityFieldService";
import { getDefaultValuesForFields } from "../../services/fieldsUtils";
import { openingStockDate } from "../../constants/globalConstants";
import dayjs from "../../config/dayjsConfig";
import { canEditEntity, canViewEntity } from "../../services/utils";
import withAuth from "../../hocs/withAuth";
function RecordManager({ searchFields, entityType, pageTitle, currentUser }) {
  let { recordType } = useParams();
  recordType = entityType ? entityType : recordType;
  if (_.isEmpty(searchFields)) {
    searchFields = getSearchColumnsForRecordType(recordType);
  }
  const today = new Date(); // Current date and time
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 30);
  let defaultValues = getDefaultValuesForFields(searchFields);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [recordSearchRequest, setRecordSeachRequest] = useState(defaultValues);
  const [startDate, setStartDate] = useState(dayjs(openingStockDate));
  const [endDate, setEndDate] = useState(dayjs(today));
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setSelectedRecord(null);
  }, [location]);

  useEffect(() => {
    if (!canViewEntity(recordType, currentUser)) {
      history.push("/");
    }
  }, [recordType, currentUser, history]);

  function handleEndDateChange(date) {
    console.log("date type is", typeof date);
    console.log("date", date);
    console.log("date", date instanceof Date);
    let newValue = "";
    setEndDate((oldValue) => {
      try {
        let finalValue = date.toISOString();
        newValue = dayjs(finalValue);
      } catch (e) {
        newValue = dayjs(today);
      }
      console.log("date new Value is", newValue);
      return newValue;
    });
  }

  function handleStartDateChange(date) {
    console.log("date type is", typeof date);
    console.log("date", date);
    console.log("date", date instanceof Date);
    let newValue = "";
    setStartDate((oldValue) => {
      try {
        let finalValue = date.toISOString();
        newValue = dayjs(finalValue);
      } catch (e) {
        newValue = dayjs(today);
      }
      return newValue;
    });
  }

  useEffect(() => {
    setSelectedRecord(null);
    setRecordSeachRequest(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordType, entityType]);

  return (
    <>
      {selectedRecord && (
        <RecordFullViewForm
          recordId={selectedRecord.id}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          isEditable={() => canEditEntity(recordType, currentUser)}
          creationAllowed={canEditEntity(recordType, currentUser)}
        />
      )}
      {!selectedRecord && (
        <RecordViewForm
          key={recordType}
          setSelectedRecord={setSelectedRecord}
          recordSearchRequest={recordSearchRequest}
          setRecordSeachRequest={setRecordSeachRequest}
          entityType={entityType}
          pageTitle={pageTitle}
          startDate={startDate}
          handleStartDateChange={handleStartDateChange}
          endDate={endDate}
          handleEndDateChange={handleEndDateChange}
          creationAllowed={canEditEntity(recordType, currentUser)}
        />
      )}
    </>
  );
}

export default withAuth(RecordManager);

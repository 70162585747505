import { materialTypeValues } from "../../../standardEntities/materialInwardFields";
import { getToday, getOpeningStockDate } from "../../../services/dateUtils";
import { Field } from "../../../types/field";
import { projectTypeValues } from "../../../standardEntities/project";
import { translatePicklistValue } from "../../../services/fieldsUtils";

export const stockTypeValues = [
  { value: "P", label: "Stock Ageing Physical Report" },
  { value: "A", label: "Stock Ageing Available Report" },
];

export const stockAgeingFormFields: Field[] = [
  {
    name: "sections",
    type: "formSection",
    visibility: { type: "EQUALS", field: "reportCategory", values: "dummy" },
    childFields: [
      {
        name: "stockType",
        label: "Report Category",
        type: "picklist",
        defaultValue: "P",
        props: {
          width: "21vw",
        },
        values: stockTypeValues,
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "docLocatorName",
        label: "Inward Locator Name",
        allowNull: true,
        props: {
          width: "21vw",
        },
        type: "lookup",
        lookupType: "locator",
        lookupFieldName: "locatorCode",
        lookupCategory: "docLocator",
        lookupParams: [
          {
            key: "projectCode",
            resolveValue: true,
            valueFieldName: "projectCode",
          },
          {
            key: "docCompanyCode",
            resolveValue: true,
            valueFieldName: "docCompanyCode",
          },
        ],
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "IN",
              field: "locatorType",
              values: ["MS", "CS"],
            },
            {
              type: "EQUALS",
              field: "locatorCode",
              resolveValue: true,
              valueFieldName: "docLocatorCode",
            },
            {
              type: "EQUALS",
              field: "stateCode",
              resolveValue: true,
              valueFieldName: "docStateCode",
            },
            {
              type: "EQUALS",
              field: "contractorCode",
              resolveValue: true,
              valueFieldName: "docContractorCode",
            },
          ],
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "docLocatorCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "locatorType",
            fieldName: "docLocatorType",
          },
          {
            additionalFieldName: "company",
            lookupFieldName: "companyCode",
            fieldName: "docCompanyCode",
          },
          {
            additionalFieldName: "company",
            lookupFieldName: "companyName",
            fieldName: "docCompanyName",
          },
          {
            additionalFieldName: "contractor",
            lookupFieldName: "companyCode",
            fieldName: "docContractorCode",
          },
          {
            additionalFieldName: "contractor",
            lookupFieldName: "companyName",
            fieldName: "docContractorName",
          },
          {
            additionalFieldName: "station",
            lookupFieldName: "stateCode",
            fieldName: "docStateCode",
          },
          {
            additionalFieldName: "station",
            lookupFieldName: "stateName",
            fieldName: "docStateName",
          },
        ],
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "docLocatorCode",
        label: "Locator",
        type: "text",
        allowNull: true,
        props: {
          width: "6vw",
        },
        autoFilled: true,
      },
      {
        name: "docCompanyName",
        label: "Inward Company Name",
        type: "text",
        autoFilled: true,
        displayOnly: true,
        allowNull: true,
        props: {
          width: "14vw",
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "docContractorName",
        label: "Contractor Name",
        type: "lookup",
        lookupType: "company",
        lookupCategory: "workClosureContractor",
        lookupFilter: { type: "AND", filters: [{ type: "IN", field: "contractorFlg", values: [true] }] },
        props: {
          width: "21vw",
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "docContractorCode",
          },
        ],
        disability: {
          type: "OR",
          filters: [
            {
              type: "EXISTS",
              field: "docLocatorCode",
            },
          ],
        },
        allowNull: true,
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "docContractorCode",
        label: "Contractor",
        type: "text",
        props: {
          width: "6vw",
        },
        autoFilled: true,
        allowNull: true,
      },
      // {
      //   name: "docStateCode",
      //   label: "State Code",
      //   type: "text",
      //   defaultValue: "",
      //   autoFilled: true,
      //   allowNull: true,
      //   props: {
      //     width: "6vw",
      //   },
      // },
      {
        name: "docStateName",
        label: "State Name",
        type: "lookup",
        lookupType: "state",
        lookupCategory: "reportingStates",
        defaultValue: null,
        allowUpdate: false,
        lookupFilter: {
          type: "EQUALS",
          field: "stateCode",
          resolveValue: true,
          valueFieldName: "docStateCode",
        },
        lookupParams: [
          {
            key: "locatorCode",
            resolveValue: true,
            valueFieldName: "docLocatorCode",
          },
        ],
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "docStateCode",
          },
        ],
        props: {
          width: "14vw",
        },
        linkedFields: [
          {
            name: "projectName",
            value: "",
          },
          {
            name: "projectCode",
            value: "",
          },
          {
            name: "projectType",
            value: "",
          },
        ],
        disability: {
          type: "OR",
          filters: [
            {
              type: "EXISTS",
              field: "docLocatorCode",
            },
          ],
        },
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "projectName",
        label: "Project Name",
        type: "lookup",
        lookupType: "project",
        lookupCategory: "docProject",
        allowNull: true,
        props: {
          width: "21vw",
        },
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "EQUALS",
              field: "projectCode",
              resolveValue: true,
              valueFieldName: "projectCode",
            },
            {
              type: "EQUALS",
              field: "stateCode",
              resolveValue: true,
              valueFieldName: "docStateCode",
            },
            {
              type: "EQUALS",
              field: "projectType",
              resolveValue: true,
              valueFieldName: "projectType",
            },
          ],
        },
        lookupParams: [
          {
            key: "docLocatorCode",
            resolveValue: true,
            valueFieldName: "docLocatorCode",
          },
          {
            key: "docCompanyCode",
            resolveValue: true,
            valueFieldName: "docCompanyCode",
          },
        ],
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "projectCode",
          },
          {
            lookupFieldName: "description",
            fieldName: "projectType",
          },
        ],
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "projectType",
        label: "Project Type",
        type: "picklist",
        defaultValue: "",
        allowNull: true,
        values: projectTypeValues,
        props: {
          width: "10vw",
        },
        disability: {
          type: "OR",
          filters: [
            {
              type: "EXISTS",
              field: "projectCode",
            },
          ],
        },
      },
      {
        name: "projectCode",
        label: "Code",
        type: "text",
        autoFilled: true,
        allowNull: true,
        defaultValue: null,
        props: {
          width: "10vw",
        },
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "itemName",
        label: "Item Name",
        type: "lookup",
        lookupType: "item",
        lookupFieldName: "itemName",
        allowNull: true,
        props: {
          width: "21vw",
        },
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "EQUALS",
              field: "itemCode",
              resolveValue: true,
              valueFieldName: "itemCode",
            },
            {
              type: "EQUALS",
              field: "itemGroupId",
              resolveValue: true,
              valueFieldName: "itemGroupId",
            },
          ],
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "itemCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "itemCustCode",
            fieldName: "itemCustCode",
          },
        ],
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "materialType",
        label: "Material Type",
        type: "picklist",
        allowNull: true,
        values: materialTypeValues,
        props: {
          width: "10vw",
        },
      },
      {
        name: "itemCustCode",
        label: "Part Code",
        type: "text",
        allowNull: true,
        autoFilled: true,
        props: {
          width: "10vw",
        },
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "fromDocDate",
        label: "Inward From Date",
        allowNull: true,
        defaultValue: getOpeningStockDate(),
        type: "date",
        constraints: [
          {
            type: "DATE_RANGE",
            minDate: getOpeningStockDate(),
            maxDateScript: {
              operation: "minDate",
              valueFields: ["toDocDate", "stockDate"],
            },
            beforeNow: true,
          },
        ],
      },
      {
        name: "toDocDate",
        label: "Inward To Date",
        allowNull: true,
        type: "date",
        defaultValue: getToday(),
        constraints: [
          {
            type: "DATE_RANGE",
            minDateScript: {
              operation: "minDate",
              values: [getOpeningStockDate()],
              valueFields: ["fromDocDate"],
            },
            maxDateScript: {
              valueFields: ["stockDate"],
            },
            beforeNow: true,
          },
        ],
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "stockDate",
        label: "Stock as on date",
        type: "date",
        defaultValue: getToday(),
        constraints: [
          {
            type: "DATE_RANGE",
            minDateScript: {
              operation: "minDate",
              values: [getOpeningStockDate()],
              valueFields: ["toDocDate"],
            },
            beforeNow: true,
          },
        ],
      },
    ],
  },
];

export const stockAgeingAvailableColumns = [
  {
    name: "docLocatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "contractorCode",
    label: "Contractor Code",
    type: "text",
  },
  {
    name: "contractorName",
    label: "Contractor Name",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "text",
  },
  {
    name: "within30_availableQuantity",
    label: "<= 30 Days Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "30To60_availableQuantity",
    label: "31 to 60 Days Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "60To120_availableQuantity",
    label: "61 to 120 Days Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "120To360_availableQuantity",
    label: "121 to 360 Days Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "moreThan360_availableQuantity",
    label: "> 360 Days Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalAvailableQuantity",
    label: "Available Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "within30_availableAmount",
    label: "<= 30 Days Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "30To60_availableAmount",
    label: "31 to 60 Days Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "60To120_availableAmount",
    label: "61 to 120 Days Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "120To360_availableAmount",
    label: "121 to 360 Days Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "moreThan360_availableAmount",
    label: "> 360 Days Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalAvailableAmount",
    label: "Available Amt",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
];

export const stockAgeingPhysicalColumns = [
  {
    name: "docLocatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "contractorCode",
    label: "Contractor Code",
    type: "text",
  },
  {
    name: "contractorName",
    label: "Contractor Name",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "text",
  },
  {
    name: "within30_physicalQuantity",
    label: "<= 30 Days Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "30To60_physicalQuantity",
    label: "31 to 60 Days Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "60To120_physicalQuantity",
    label: "61 to 120 Days Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "120To360_physicalQuantity",
    label: "121 to 360 Days Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "moreThan360_physicalQuantity",
    label: "> 360 Days Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalPhysicalQuantity",
    label: "Physical Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "within30_physicalAmount",
    label: "<= 30 Days Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "30To60_physicalAmount",
    label: "31 to 60 Days Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "60To120_physicalAmount",
    label: "61 to 120 Days Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "120To360_physicalAmount",
    label: "121 to 360 Days Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "moreThan360_physicalAmount",
    label: "> 360 Days Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalPhysicalAmount",
    label: "Physical Amt",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
];

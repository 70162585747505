import { Field } from "../types/field";
export const itemFields: Field[] = [
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
    allowUpdate: true,
  },
  {
    name: "section3",
    type: "formSection",
    childFields: [
      {
        name: "itemCustCode",
        label: "Part Code",
        type: "text",
      },
      {
        name: "uom",
        label: "UOM",
        type: "picklist",
        defaultValue: "NOS",
        values: [
          { value: "NOS", label: "Number" },
          { value: "MTR", label: "Meter" },
          { value: "KG", label: "Kg" },
          { value: "SET", label: "Set" },
        ],
        linkedFields: [
          {
            name: "decimalFlg",
            value: false,
          },
        ],
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "hsnCode",
        label: "HSN Code",
        type: "text",
        allowUpdate: true,
      },
      {
        name: "unitRate",
        label: "Indicative Unit Rate",
        type: "number",
        decimal: true,
        defaultValue: 0,
        allowUpdate: true,
      },
    ],
  },
  {
    name: "section3",
    type: "formSection",
    childFields: [
      {
        name: "itemGroupName",
        label: "Item Group Name",
        type: "lookup",
        props: {
          width: "21vw",
        },
        lookupType: "itemGroup",
        allowUpdate: true,
        lookupFieldName: "itemGroupName",
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "EQUALS",
              field: "itemGroupId",
              resolveValue: true,
              valueFieldName: "itemGroupId",
            },
          ],
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "itemGroupId",
          },
        ],
      },
      // {
      //   name: "itemGroupId",
      //   label: "Group Id",
      //   type: "text",
      //   autoFilled: true,
      //   allowNull: true,
      //   props: {
      //     width: "4vw",
      //   },
      // },
    ],
  },
  {
    name: "section3",
    type: "formSection",
    childFields: [
      {
        name: "itemGroupId",
        label: "Group Id",
        type: "text",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "5vw",
        },
      },
      {
        name: "id",
        label: "Item Id",
        type: "text",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "5vw",
        },
      },
      {
        name: "itemCode",
        label: "Item Code",
        type: "text",
        allowNull: true,
        autoFilled: true,
        props: {
          width: "9vw",
        },
      },
    ],
  },
  {
    name: "oitemName",
    label: "Old Item Name",
    type: "text",
    autoFilled: true,
    allowNull: true,
  },
  {
    name: "section2",
    type: "formSection",
    childFields: [
      {
        name: "decimalFlg",
        label: "Decimal",
        type: "checkbox",
        allowUpdate: true,
        defaultValue: false,
        disability: {
          type: "NIN",
          field: "uom",
          values: ["MTR", "KG"],
        },
      },
      {
        name: "active",
        label: "Active",
        type: "checkbox",
        defaultValue: false,
        allowUpdate: true,
      },
    ],
  },
];

export const itemColumns = [
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "text",
  },
  {
    name: "unitRate",
    label: "Unit Rate",
    type: "number",
  },
  {
    name: "itemGroupName",
    label: "Item Group",
    type: "number",
  },
  // {
  //   name: "serialAllow",
  //   label: "Serial Allow",
  //   type: "text",
  //   format: (value) => (value ? "True" : "False"),
  // },
  {
    name: "decimalFlg",
    label: "Decimal",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "id",
    label: "Item Id",
    type: "text",
  },
];

export const itemExcelColumns = [
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "text",
  },
  {
    name: "unitRate",
    label: "Unit Rate",
    type: "text",
  },
  {
    name: "hsnCode",
    label: "HSN Code",
    type: "text",
  },
  {
    name: "itemGroupName",
    label: "Item Group",
    type: "text",
  },
  {
    name: "itemGroupId",
    label: "Group Id",
    type: "text",
  },
  {
    name: "decimalFlg",
    label: "Decimal",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  // {
  //   name: "serialAllow",
  //   label: "Serial Allow",
  //   type: "text",
  //   format: (value) => (value ? "True" : "False"),
  // },
  // {
  //   name: "serialReqd",
  //   label: "Serial Reqd",
  //   type: "text",
  //   format: (value) => (value ? "True" : "False"),
  // },
  // {
  //   name: "serialMin",
  //   label: "Min Serial Width",
  //   type: "text",
  // },
  // {
  //   name: "serialMax",
  //   label: "Max Serial Width",
  //   type: "text",
  // },
  {
    name: "id",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCode",
    label: "Item Code",
    type: "text",
  },
];

export const itemSearchColumns = [
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
    props: {
      widthFactor: 2,
    },
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "picklist",
    values: [
      { value: "NOS", label: "Number" },
      { value: "MTR", label: "Meter" },
      { value: "KG", label: "Kg" },
      { value: "SET", label: "Set" },
    ],
  },
  {
    name: "itemGroupName",
    label: "Item Group",
    type: "text",
  },
  {
    name: "serialAllow",
    label: "Serial Allow",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
  {
    name: "decimalFlg",
    label: "Decimal",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
  {
    name: "active",
    label: "Active",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
];

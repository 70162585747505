import withAuth from "../../hocs/withAuth";
import GenerateReportForm from "./GenerateReportForm";
import {
  stockOpeningClosingFormFields,
  stockOpenCloseAvailableColumns,
  stockOpenClosePhysicalColumns,
} from "./formFields/stockOpeningClosingFormFields";
import { getOpeningStockDate, getToday } from "../../services/dateUtils";

const getExcelColumns = (searchRequest) => {
  return reportCategoryToExcelColumns[searchRequest.stockType];
};

const reportCategoryToExcelColumns = {
  A: stockOpenCloseAvailableColumns,
  P: stockOpenClosePhysicalColumns,
};

const StockReportForm = ({ formMinWidth = "38vw" }) => {
  return (
    <GenerateReportForm
      formMinWidth={formMinWidth}
      fields={stockOpeningClosingFormFields}
      getExcelColumns={getExcelColumns}
      pageTitle={"Stock Opening Closing Report"}
      getReportName={(searchRequest) => `Stock Opening Closing Report`}
      reportType={"stock-opening-closing"}
      preProcessReportSearchRequest={(searchRequest) => {
        searchRequest.stockOpeningDate = searchRequest.stockOpeningDate ? searchRequest.stockOpeningDate : getOpeningStockDate();
        searchRequest.stockClosingDate = searchRequest.stockClosingDate ? searchRequest.stockClosingDate : getToday();
        return searchRequest;
      }}
    />
  );
};

export default withAuth(StockReportForm);

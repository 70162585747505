import withAuth from "../../hocs/withAuth";
import GenerateReportForm from "./GenerateReportForm";
import { translatePicklistValue } from "../../services/fieldsUtils";
import { stockLedgerCategoryValues, stockLedgerFields, stockLedgerLocatorReport, stockLedgerProjectReport } from "./formFields/stockLedgerFormFields";
import { getOpeningStockDate, getToday } from "../../services/dateUtils";

const getExcelColumns = (searchRequest) => {
  return reportCategoryToExcelColumns[searchRequest.reportCategory];
};

const reportCategoryToExcelColumns = {
  LOCATOR_DETAIL: stockLedgerLocatorReport,
  PROJECT_DETAIL: stockLedgerProjectReport,
};

const StockReportForm = ({ formMinWidth = "38vw" }) => {
  return (
    <GenerateReportForm
      formMinWidth={formMinWidth}
      fields={stockLedgerFields}
      getExcelColumns={getExcelColumns}
      pageTitle={"Stock Ledger Report"}
      getReportName={(searchRequest) => `${translatePicklistValue(searchRequest.reportCategory, stockLedgerCategoryValues)}`}
      reportType={"stock-ledger"}
      preProcessReportSearchRequest={(searchRequest) => {
        searchRequest.stockOpeningDate = searchRequest.stockOpeningDate ? searchRequest.stockOpeningDate : getOpeningStockDate();
        searchRequest.stockClosingDate = searchRequest.stockClosingDate ? searchRequest.stockClosingDate : getToday();
        return searchRequest;
      }}
    />
  );
};

export default withAuth(StockReportForm);

import withAuth from "../../hocs/withAuth";
import RecordAdditionForm from "../recordStandardForms/RecordAdditionForm";
import { User } from "./UserTypes";

function UserAdditionForm() {
  return (
    <RecordAdditionForm
      formMinWidth={"38vw"}
      entityType="user"
      isUnSubmitRequired={() => false}
      canDelete={(recordType, currentUser, user: User) => {
        return user.approvedAt == null;
      }}
    />
  );
}

export default withAuth(UserAdditionForm);

export const validateUser = ({ user, setFormErrors }) => {
  const newErrors: any = {};

  // Check if the user ID is valid
  const userIdRegex = /^(?![0-9._])[a-zA-Z0-9._]{6,}$/;
  if (!userIdRegex.test(user.userId)) {
    newErrors.userId = "User ID must start with a letter and be at least 6 characters, allowing letters, numbers, dots, and underscores.";
  }

  // Check if the email is a valid email address
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  if (!emailRegex.test(user.email)) {
    newErrors.email = "Invalid email address";
  }

  if (!validatePassword(user.password)) {
    newErrors.password = "Password must have at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character";
  }

  // Check if the mobile number is a valid 10-digit number
  const phoneRegex = /^\d{10}$/;
  const phoneString = user.mobile.toString().trim();

  if (phoneString.length !== 10 || !phoneRegex.test(phoneString)) {
    newErrors.mobile = "Mobile number must have exactly 10 digits";
  }

  if (phoneString[0] === "0") {
    newErrors.mobile = "Mobile number must not have leading 0";
  }

  setFormErrors(newErrors);
  // Return true if there are no errors, otherwise false
  return Object.keys(newErrors).length === 0;
};

// Password validation function
export const validatePassword = (password: string) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return regex.test(password);
};

import { formatDate } from "../services/dateUtils";
import { Field } from "../types/field";
import { projectTypeValues } from "../standardEntities/project";
import { materialTypeValues } from "../standardEntities/materialInwardFields";
import { translatePicklistValue } from "../services/fieldsUtils";

export const outTransactionTypeValues = [
  { value: "OT", label: "Owner Site Transfer" },
  { value: "ET", label: "External Site Transfer" },
  { value: "MS", label: "Main Store Transfer", visibility: { type: "IN", field: "withinState", values: [true] } },
  { value: "CS", label: "Contractor Transfer", visibility: { type: "IN", field: "withinState", values: [true] } },
  { value: "IS", label: "Internal Shift", visibility: { type: "IN", field: "withinState", values: [true] } },
  { value: "SL", label: "Stock Loss", visibility: { type: "IN", field: "withinState", values: [true] } },
];

const childWorkOrderRequiredFilter = {
  type: "AND",
  filters: [
    { type: "IN", field: "transactionType", values: ["CS"] },
    { type: "IN", field: "projectType", values: ["FTTH"] },
  ],
};

const notChildWorkOrderRequiredFilter = {
  type: "NOT",
  filters: [childWorkOrderRequiredFilter],
};

const childWorkOrderAllowFilter = {
  type: "AND",
  filters: [
    { type: "IN", field: "transactionType", values: ["CS", "MS", "IS", "SL"] },
    { type: "IN", field: "projectType", values: ["FTTH"] },
  ],
};

export const receiverDocTypeValues = [
  { value: "PO", label: "Purchase Order", visibility: notChildWorkOrderRequiredFilter },
  { value: "LO", label: "Lease Order", visibility: notChildWorkOrderRequiredFilter },
  { value: "MR", label: "Material Request", visibility: notChildWorkOrderRequiredFilter },
  { value: "CO", label: "Child Work Order", visibility: childWorkOrderAllowFilter },
  { value: "NA", label: "N/A", visibility: notChildWorkOrderRequiredFilter },
];

export const referenceDocTypeValues = [{ value: "NA", label: "N/A" }];

export const senderDocTypeValues = [
  { value: "SI", label: "Sales Invoice" },
  { value: "DC", label: "Delivery Challan" },
  {
    value: "CO",
    label: "Child Work Order",
    visibility: {
      type: "AND",
      filters: [
        { type: "IN", field: "docLocatoryType", values: ["CS"] },
        { type: "IN", field: "projectType", values: ["FTTH"] },
      ],
    },
  },
  { value: "NA", label: "N/A" },
];

export const materialOutwardDetailFields: Field[] = [
  {
    name: "sno",
    label: "SNo.",
    type: "text",
    autoFilled: true,
    allowNull: true,
    props: {
      widthFactor: 0.5,
    },
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "lookup",
    lookupType: "item",
    lookupFieldName: "itemName",
    lookupCategory: "outwardItem",
    lookupFilter: {
      type: "AND",
      filters: [
        {
          type: "EQUALS",
          field: "itemId",
          resolveValue: true,
          valueFieldName: "itemId",
        },
      ],
    },
    lookupParams: [
      {
        key: "docLocatorCode",
        resolveValue: true,
        valueFieldName: "docLocatorCode",
        isGlobalConstant: true,
      },
      {
        key: "materialType",
        resolveValue: true,
        valueFieldName: "materialType",
        isGlobalConstant: true,
      },
      {
        key: "projectCode",
        resolveValue: true,
        valueFieldName: "projectCode",
        isGlobalConstant: true,
      },
      {
        key: "selectedMirRowIds",
        resolveValue: true,
        isGlobalConstant: true,
        valueFieldName: "mirRowIds",
      },
      {
        key: "outwardDocDate",
        resolveValue: true,
        valueFieldName: "docDate",
        isGlobalConstant: true,
      },
    ],
    dependentFields: [
      {
        lookupFieldName: "id",
        fieldName: "itemCode",
      },
      {
        lookupFieldName: "description",
        fieldName: "itemCustCode",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "id",
        fieldName: "itemId",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "uom",
        fieldName: "uom",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "decimalFlg",
        fieldName: "decimalFlg",
      },
    ],
    props: {
      widthFactor: 3,
    },
    linkedFields: [
      {
        name: "mirRowId",
        value: "",
      },
    ],
  },
  // {
  //   name: "itemCode",
  //   label: "Item Code",
  //   type: "text",
  //   autoFilled: true,
  //   props: {
  //     widthFactor: 1.4,
  //   },
  // },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
    allowNull: true,
    autoFilled: true,
    props: {
      widthFactor: 1.6,
    },
  },
  {
    name: "uom",
    label: "Unit",
    type: "text",
    autoFilled: true,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "mirRowId",
    label: "MIR Row Id",
    type: "lookupTable",
    lookupType: "material-inward-dtl",
    lookupCategory: "outwardRow",
    lookupFieldName: "mirId",
    defaultValue: "",
    props: {
      widthFactor: 1.4,
    },
    lookupFilter: {
      type: "AND",
      filters: [
        {
          field: "itemId",
          type: "EQUALS",
          resolveValue: true,
          isGlobalConstant: true,
          valueFieldName: "itemId",
        },
        {
          field: "docLocatorCode",
          type: "EQUALS",
          resolveValue: true,
          isGlobalConstant: true,
          valueFieldName: "docLocatorCode",
        },
        {
          field: "materialType",
          type: "EQUALS",
          resolveValue: true,
          isGlobalConstant: true,
          valueFieldName: "materialType",
        },
        {
          field: "projectCode",
          type: "EQUALS",
          resolveValue: true,
          isGlobalConstant: true,
          valueFieldName: "projectCode",
        },
        {
          field: "id",
          type: "NIN",
          resolveValue: true,
          isGlobalConstant: true,
          valueFieldName: "mirRowIds",
        },
        {
          field: "docDate",
          type: "LTE",
          resolveValue: true,
          isGlobalConstant: true,
          valueFieldName: "docDate",
        },
      ],
    },
    lookupParams: [
      {
        key: "docLocatorCode",
        resolveValue: true,
        valueFieldName: "docLocatorCode",
        isGlobalConstant: true,
      },
      {
        key: "materialType",
        resolveValue: true,
        valueFieldName: "materialType",
        isGlobalConstant: true,
      },
      {
        key: "projectCode",
        resolveValue: true,
        valueFieldName: "projectCode",
        isGlobalConstant: true,
      },
    ],
    disability: {
      type: "OR",
      filters: [
        {
          type: "MISSING",
          field: "itemId",
        },
        // {
        //   type: "MISSING",
        //   field: "docLocatorCode",
        // },
      ],
    },
    tableColumns: [
      {
        name: "id",
        label: "MIR Row Id",
        type: "text",
      },
      {
        name: "mirId",
        label: "MIR No",
        type: "text",
      },
      {
        name: "docDate",
        label: "MIR Date",
        type: "date",
        format: (value) => formatDate(value),
      },
      {
        name: "transactionType",
        label: "Transaction Type",
        type: "text",
      },
      // {
      //   name: "partSerialNo",
      //   label: "Part Serial",
      //   type: "text",
      // },
      // {
      //   name: "batchNo",
      //   label: "Batch No",
      //   type: "text",
      // },
      {
        name: "availableQuantity",
        label: "Available Quantity",
        type: "text",
      },
    ],
    dependentFields: [
      {
        additionalFieldName: "details",
        lookupFieldName: "mirId",
        fieldName: "mirId",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "availableQuantity",
        fieldName: "mirAvailableQuantity",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "serialNo",
        fieldName: "mirSerialNo",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "partSerialNo",
        fieldName: "partSerialNo",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "rate",
        fieldName: "rate",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "batchNo",
        fieldName: "batchNo",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "availableQuantity",
        fieldName: "availableQuantity",
      },
    ],
  },
  {
    name: "mirId",
    label: "MIR No",
    type: "text",
    autoFilled: true,
  },
  // {
  //   name: "partSerialNo",
  //   label: "Part Serial",
  //   type: "text",
  //   allowNull: true,
  //   autoFilled: true,
  // },
  {
    name: "availableQuantity",
    label: "Available Quantity",
    type: "text",
    allowNull: true,
    autoFilled: true,
  },
  {
    name: "totalQuantity",
    label: "Out Quantity",
    type: "number",
    defaultValue: 0,
    allowUpdate: false,
    decimalFlg: true,
    constraints: [{ type: "RANGE", maxValueScript: { valueFields: ["availableQuantity"] }, minLimit: 0 }],
  },
  {
    name: "rate",
    label: "Rate",
    type: "number",
    decimal: true,
    autoFilled: true,
  },
  {
    name: "totalAmount",
    label: "Total Amount",
    type: "number",
    decimal: true,
    autoFilled: true,
    valueScript: { operation: "multi", valueFields: ["rate", "totalQuantity"] },
    props: {
      widthFactor: 1,
    },
  },
  {
    name: "id",
    label: "Row Id",
    type: "text",
    allowNull: true,
    autoFilled: true,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
    autoFilled: true,
    props: {
      widthFactor: 0.8,
    },
  },
];

export const materialOutwardFields: Field[] = [
  {
    name: "sectionA",
    type: "formSection",
    alignment: "row",
    props: {
      width: "25vw",
      spacing: "2vw",
    },
    childFields: [
      {
        name: "section1",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docLocatorName",
                label: "Sender Locator Name",
                props: {
                  width: "17vw",
                },
                type: "lookup",
                lookupType: "locator",
                lookupFieldName: "locatorCode",
                lookupCategory: "docLocator",
                lookupParams: [
                  {
                    key: "projectCode",
                    resolveValue: true,
                    valueFieldName: "projectCode",
                  },
                  {
                    key: "docCompanyCode",
                    resolveValue: true,
                    valueFieldName: "docCompanyCode",
                  },
                ],
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "IN",
                      field: "locatorType",
                      values: ["MS", "CS"],
                    },
                    {
                      type: "EQUALS",
                      field: "locatorCode",
                      resolveValue: true,
                      valueFieldName: "docLocatorCode",
                    },
                  ],
                },
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "docLocatorCode",
                  },
                  {
                    additionalFieldName: "company",
                    lookupFieldName: "companyCode",
                    fieldName: "docCompanyCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "locatorType",
                    fieldName: "docLocatorType",
                  },
                  {
                    additionalFieldName: "company",
                    lookupFieldName: "companyName",
                    fieldName: "docCompanyName",
                  },
                  {
                    additionalFieldName: "contractor",
                    lookupFieldName: "companyCode",
                    fieldName: "docContractorCode",
                  },
                  {
                    additionalFieldName: "contractor",
                    lookupFieldName: "companyName",
                    fieldName: "docContractorName",
                  },
                  {
                    lookupFieldName: "description",
                    fieldName: "docStationCode",
                  },
                  {
                    additionalFieldName: "station",
                    lookupFieldName: "stationName",
                    fieldName: "docStationName",
                  },
                  {
                    additionalFieldName: "station",
                    lookupFieldName: "stateName",
                    fieldName: "docStateName",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "address1",
                    fieldName: "docAddress1",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "address2",
                    fieldName: "docAddress2",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "gstNo",
                    fieldName: "docGSTNo",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "todayDate",
                    fieldName: "docDate",
                  },
                  {
                    additionalFieldName: "station",
                    lookupFieldName: "stateCode",
                    fieldName: "docStateCode",
                  },
                  {
                    additionalFieldName: "contractor",
                    lookupFieldName: "companyCode",
                    fieldName: "docContractorCode",
                  },
                ],
                linkedFields: [
                  {
                    name: "projectCode",
                    value: "",
                  },
                  {
                    name: "projectName",
                    value: "",
                  },
                  {
                    name: "projectType",
                    value: "FF",
                  },
                  {
                    name: "transactionType",
                    value: "OT",
                  },
                  {
                    name: "receiverLocatorName",
                    value: "",
                  },
                  {
                    name: "receiverLocatorCode",
                    value: "",
                  },
                  {
                    name: "receiverCompanyName",
                    value: "",
                  },
                  {
                    name: "receiverCompanyCode",
                    value: "",
                  },
                  {
                    name: "receiverContractorName",
                    value: "",
                  },
                  {
                    name: "receiverContractorCode",
                    value: "",
                  },
                  {
                    name: "senderDocNo",
                    value: null,
                  },
                  {
                    name: "senderDocDate",
                    value: null,
                  },
                ],
                disability: {
                  type: "EXISTS",
                  field: "details",
                },
              },
              {
                name: "docLocatorCode",
                label: "Code",
                type: "text",
                props: {
                  width: "7vw",
                },
                autoFilled: true,
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docCompanyCode",
                label: "Code",
                type: "text",
                autoFilled: true,
                defaultValue: null,
                props: {
                  width: "7vw",
                },
              },
              {
                name: "docCompanyName",
                label: "Sender Company Name",
                type: "text",
                autoFilled: true,
                displayOnly: true,
                props: {
                  width: "17vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docContractorName",
                label: "Contractor Name",
                type: "text",
                autoFilled: true,
                allowNull: true,
                displayOnly: true,
                props: {
                  width: "17vw",
                },
              },
              {
                name: "docContractorCode",
                label: "Contractor",
                type: "text",
                autoFilled: true,
                allowNull: true,
                defaultValue: null,
                props: {
                  width: "7vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "projectType",
                label: "Project Type",
                type: "picklist",
                defaultValue: "FF",
                values: projectTypeValues,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "docLocatorCode",
                    },
                  ],
                },
                linkedFields: [
                  {
                    name: "transactionType",
                    value: "OT",
                  },
                  {
                    name: "projectName",
                    value: "",
                  },
                  {
                    name: "projectCode",
                    value: "",
                  },
                ],
              },
              {
                name: "materialType",
                label: "Material Type",
                type: "picklist",
                defaultValue: "F",
                values: materialTypeValues,
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "docLocatorCode",
                    },
                  ],
                },
                props: {
                  width: "12vw",
                },
                linkedFields: [
                  {
                    name: "receiverMaterialType",
                    filteredValues: [
                      {
                        filter: { type: "NIN", field: "transactionType", values: ["IS"] },
                        resolveValue: true,
                        valueFieldName: "materialType",
                      },
                    ],
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "projectName",
                label: "Project Name",
                type: "lookup",
                lookupType: "project",
                lookupCategory: "outwardProject",
                props: {
                  width: "17vw",
                },
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "EQUALS",
                      field: "projectCode",
                      resolveValue: true,
                      valueFieldName: "projectCode",
                    },
                    {
                      type: "EQUALS",
                      field: "projectType",
                      resolveValue: true,
                      valueFieldName: "projectType",
                    },
                  ],
                },
                lookupParams: [
                  {
                    key: "docLocatorCode",
                    resolveValue: true,
                    valueFieldName: "docLocatorCode",
                  },
                  {
                    key: "projectType",
                    resolveValue: true,
                    valueFieldName: "projectType",
                  },
                ],
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "projectCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "virtualLocator",
                    fieldName: "virtualLocator",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "closed",
                    fieldName: "projectClosed",
                  },
                ],
                warningMessageConfig: {
                  filter: { type: "IN", field: "projectClosed", values: [true] },
                  message: "Project is closed",
                },
                linkedFields: [
                  {
                    name: "transactionType",
                    value: "OT",
                  },
                  {
                    name: "receiverLocatorName",
                    value: "",
                  },
                  {
                    name: "receiverLocatorCode",
                    value: "",
                  },
                  {
                    name: "receiverCompanyName",
                    value: "",
                  },
                  {
                    name: "receiverCompanyCode",
                    value: "",
                  },
                  {
                    name: "receiverContractorName",
                    value: "",
                  },
                  {
                    name: "receiverContractorCode",
                    value: "",
                  },
                  {
                    name: "receiverProjectName",
                    value: "",
                  },
                  {
                    name: "receiverProjectCode",
                    value: "",
                  },
                  // {
                  //   name: "senderDocNo",
                  //   value: null,
                  // },
                  // {
                  //   name: "senderDocDate",
                  //   value: null,
                  // },
                ],
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "docLocatorCode",
                    },
                  ],
                },
              },
              {
                name: "projectCode",
                label: "Code",
                type: "text",
                autoFilled: true,
                defaultValue: null,
                props: {
                  width: "7vw",
                },
                // linkedFields: [
                //   {
                //     name: "receiverProjectCode",
                //     filteredValues: [
                //       {
                //         filter: { type: "NIN", field: "transactionType", values: ["IS", "CS"] },
                //         resolveValue: true,
                //         valueFieldName: "projectCode",
                //       },
                //     ],
                //     value: "",
                //   },
                // ],
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docStationName",
                label: "Sender Station",
                type: "text",
                defaultValue: "",
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "docStateName",
                label: "Sender State",
                type: "text",
                defaultValue: "",
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "senderDocType",
                label: "Sender Doc Type",
                type: "picklist",
                allowUpdate: true,
                defaultValue: "NA",
                props: {
                  width: "12vw",
                },
                values: senderDocTypeValues,
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "MISSING",
                      field: "projectCode",
                    },
                    {
                      type: "MISSING",
                      field: "docLocatorCode",
                    },
                    {
                      type: "AND",
                      filters: [
                        {
                          type: "IN",
                          field: "projectType",
                          values: ["FTTH"],
                        },
                        {
                          type: "IN",
                          field: "docLocatorType",
                          values: ["CS"],
                        },
                      ],
                    },
                  ],
                },
                linkedFields: [
                  { name: "senderDocNo", value: null },
                  { name: "senderDocDate", value: null },
                ],
              },
              {
                name: "docGSTNo",
                label: "Sender GST No",
                type: "text",
                allowNull: true,
                autoFilled: true,
                displayOnly: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "senderDocNo",
                label: "Sender Doc No",
                type: "lookup",
                lookupType: "child-work-order",
                lookupCategory: "inwardChildWorkOrder",
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "EQUALS",
                      field: "contractorCode",
                      resolveValue: true,
                      valueFieldName: "docContractorCode",
                    },
                    {
                      type: "EQUALS",
                      field: "projectCode",
                      resolveValue: true,
                      valueFieldName: "projectCode",
                    },
                  ],
                },
                allowUpdate: true,
                defaultValue: "",
                dependentFields: [
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "cwoDate",
                    fieldName: "senderDocDate",
                  },
                ],
                props: {
                  width: "12vw",
                },
                visibility: {
                  type: "IN",
                  field: "senderDocType",
                  values: ["CO"],
                },
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "NIN",
                      field: "senderDocType",
                      values: ["CO"],
                    },
                    {
                      type: "MISSING",
                      field: "projectCode",
                    },
                    {
                      type: "MISSING",
                      field: "docContractorCode",
                    },
                  ],
                },
              },
              {
                name: "senderDocNo",
                label: "Sender Doc No",
                type: "text",
                defaultValue: "",
                allowUpdate: true,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "IN",
                  field: "senderDocType",
                  values: ["NA", "CO"],
                },
                visibility: {
                  type: "NIN",
                  field: "senderDocType",
                  values: ["CO"],
                },
              },
              {
                name: "senderDocDate",
                label: "Sender Doc Date",
                type: "date",
                constraints: [{ type: "DATE_RANGE", beforeNow: true }],
                allowUpdate: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "IN",
                  field: "senderDocType",
                  values: ["NA"],
                },
              },
            ],
          },
        ],
      },
      {
        name: "section2",
        type: "formSection",
        alignment: "column",
        props: {
          width: "21vw",
          spacing: "0.75vw",
          justify: "top",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            props: {
              justify: "right",
            },
            childFields: [
              {
                name: "withinState",
                label: "Within State",
                type: "checkbox",
                defaultValue: true,
                allowUpdate: true,
                linkedFields: [
                  { name: "transactionType", value: "OT" },
                  { name: "receiverLocatorName", value: "" },
                  { name: "receiverCompanyName", value: "" },
                  { name: "receiverContractorName", value: "" },
                ],
                disability: {
                  type: "EXISTS",
                  field: "details",
                },
              },
              {
                name: "sections",
                type: "formSection",
                childFields: [
                  {
                    name: "transactionType",
                    label: "Transaction Type",
                    type: "picklist",
                    defaultValue: "OT",
                    allowUpdate: true,
                    values: outTransactionTypeValues,
                    linkedFields: [
                      {
                        name: "receiverLocatorName",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["IS", "SL"] },
                            resolveValue: true,
                            valueFieldName: "docLocatorName",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverLocatorCode",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["IS", "SL"] },
                            resolveValue: true,
                            valueFieldName: "docLocatorCode",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverCompanyName",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["IS", "SL", "CS", "MS"] },
                            resolveValue: true,
                            valueFieldName: "docCompanyName",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverCompanyCode",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["IS", "SL", "CS", "MS"] },
                            resolveValue: true,
                            valueFieldName: "docCompanyCode",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverContractorName",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["IS", "SL"] },
                            resolveValue: true,
                            valueFieldName: "docContractorName",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverContractorCode",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["IS", "SL"] },
                            resolveValue: true,
                            valueFieldName: "docContractorCode",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverProjectType",
                        resolveValue: true,
                        valueFieldName: "projectType",
                      },
                      {
                        name: "receiverMaterialType",
                        filteredValues: [
                          {
                            filter: { type: "NIN", field: "transactionType", values: ["IS"] },
                            resolveValue: true,
                            valueFieldName: "materialType",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverProjectName",
                        filteredValues: [
                          {
                            filter: { type: "NIN", field: "transactionType", values: ["IS", "CS"] },
                            resolveValue: true,
                            valueFieldName: "projectName",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverProjectCode",
                        filteredValues: [
                          {
                            filter: { type: "NIN", field: "transactionType", values: ["IS", "CS"] },
                            resolveValue: true,
                            valueFieldName: "projectCode",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "receiverDocNo",
                        value: "",
                      },
                      {
                        name: "receiverDocDate",
                        value: "",
                      },
                    ],
                    props: {
                      width: "10vw",
                    },
                    disability: {
                      type: "OR",
                      filters: [
                        {
                          type: "EXISTS",
                          field: "details",
                        },
                        {
                          type: "MISSING",
                          field: "docLocatorCode",
                        },
                        {
                          type: "MISSING",
                          field: "projectCode",
                        },
                      ],
                    },
                  },
                ],
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "virtualLocator",
                label: "Sender Virtual Locator",
                type: "text",
                allowNull: true,
                autoFilled: true,
                displayOnly: true,
                props: {
                  width: "21vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "IN",
              field: "transactionType",
              values: ["IS", "SL", "CS", "MS"],
            },
            childFields: [
              {
                name: "receiverVirtualLocator",
                label: "Receiver Virtual Locator",
                type: "text",
                allowNull: true,
                autoFilled: true,
                displayOnly: true,
                props: {
                  width: "21vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "id",
                label: "MOA No",
                type: "text",
                displayOnly: true,
                allowNull: true,
                props: {
                  width: "10vw",
                },
              },
              {
                name: "docDate",
                label: "MOA Date",
                type: "date",
                displayOnly: true,
                props: {
                  width: "10vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            props: {
              width: "21vw",
              justify: "center",
            },
            childFields: [
              {
                name: "gpFlg",
                label: "Is GP Made",
                type: "checkbox",
                props: {
                  width: "10vw",
                  justify: "center",
                },
                defaultValue: false,
                displayOnly: true,
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "IN",
              field: "gpFlg",
              values: ["1"],
            },
            childFields: [
              {
                name: "gpId",
                label: "Gate Pass Id",
                type: "number",
                allowNull: true,
                autoFilled: true,
                props: {
                  width: "10vw",
                },
              },
              {
                name: "gpDate",
                label: "Gate Pass Date",
                type: "date",
                allowNull: true,
                autoFilled: true,
                props: {
                  width: "10vw",
                },
              },
            ],
          },
        ],
      },
      {
        name: "section3",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "receiverCompanyName",
                label: "Receiver Company Name",
                type: "lookup",
                lookupType: "company",
                allowUpdate: true,
                lookupCategory: "receiverCompany",
                props: {
                  width: "17vw",
                },
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "receiverCompanyCode",
                  },
                ],
                lookupParams: [
                  {
                    key: "docCompanyCode",
                    resolveValue: true,
                    valueFieldName: "docCompanyCode",
                  },
                  {
                    key: "transactionType",
                    resolveValue: true,
                    valueFieldName: "transactionType",
                  },
                  {
                    key: "receiverProjectCode",
                    resolveValue: true,
                    valueFieldName: "receiverProjectCode",
                  },
                  {
                    key: "receiverLocatorCode",
                    resolveValue: true,
                    valueFieldName: "receiverLocatorCode",
                  },
                  {
                    key: "withinState",
                    resolveValue: true,
                    valueFieldName: "withinState",
                  },
                ],
                linkedFields: [
                  {
                    name: "receiverLocatorName",
                    value: "",
                  },
                  {
                    name: "receiverLocatorCode",
                    value: "",
                  },
                  {
                    name: "receiverContractorName",
                    value: "",
                  },
                  {
                    name: "receiverContractorCode",
                    value: "",
                  },
                ],
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "docCompanyCode",
                    },
                    {
                      type: "MISSING",
                      field: "projectCode",
                    },
                    {
                      type: "IN",
                      field: "transactionType",
                      values: ["IS", "SL", "CS", "MS"],
                    },
                  ],
                },
              },
              {
                name: "receiverCompanyCode",
                label: "Code",
                type: "text",
                autoFilled: true,
                allowNull: true,
                props: {
                  width: "7vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "receiverLocatorCode",
                label: "Code",
                type: "text",
                autoFilled: true,
                allowNull: true,
                props: {
                  width: "7vw",
                },
              },
              {
                name: "receiverLocatorName",
                label: "Receiver Locator Name",
                type: "lookup",
                lookupType: "locator",
                lookupCategory: "receiverLocator",
                allowUpdate: true,
                props: {
                  width: "17vw",
                },
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "EQUALS",
                      field: "locatorCode",
                      resolveValue: true,
                      valueFieldName: "receiverLocatorCode",
                    },
                    {
                      type: "EQUALS",
                      field: "companyCode",
                      resolveValue: true,
                      valueFieldName: "receiverCompanyCode",
                    },
                  ],
                },
                lookupParams: [
                  {
                    key: "withinState",
                    resolveValue: true,
                    valueFieldName: "withinState",
                  },
                  {
                    key: "docStateCode",
                    resolveValue: true,
                    valueFieldName: "docStateCode",
                  },
                  {
                    key: "docCompanyCode",
                    resolveValue: true,
                    valueFieldName: "docCompanyCode",
                  },
                  {
                    key: "docProjectCode",
                    resolveValue: true,
                    valueFieldName: "projectCode",
                  },
                  {
                    key: "docLocatorCode",
                    resolveValue: true,
                    valueFieldName: "docLocatorCode",
                  },
                  {
                    key: "transactionType",
                    resolveValue: true,
                    valueFieldName: "transactionType",
                  },
                  {
                    key: "receiverProjectCode",
                    resolveValue: true,
                    valueFieldName: "receiverProjectCode",
                  },
                  {
                    key: "receiverProjectType",
                    resolveValue: true,
                    valueFieldName: "receiverProjectType",
                  },
                  {
                    key: "locatorCompany",
                    resolveValue: true,
                    valueFieldName: "receiverCompanyCode",
                  },
                ],
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "receiverLocatorCode",
                  },
                  {
                    lookupFieldName: "description",
                    fieldName: "receiverStationCode",
                  },
                  {
                    additionalFieldName: "contractor",
                    lookupFieldName: "companyCode",
                    fieldName: "receiverContractorCode",
                  },
                  {
                    additionalFieldName: "contractor",
                    lookupFieldName: "companyName",
                    fieldName: "receiverContractorName",
                  },
                  {
                    additionalFieldName: "station",
                    lookupFieldName: "stationName",
                    fieldName: "receiverStationName",
                  },
                  {
                    additionalFieldName: "station",
                    lookupFieldName: "stateCode",
                    fieldName: "receiverStateCode",
                  },
                  {
                    additionalFieldName: "station",
                    lookupFieldName: "stateName",
                    fieldName: "receiverStateName",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "gstNo",
                    fieldName: "receiverGSTNo",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "address1",
                    fieldName: "receiverAddress1",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "address2",
                    fieldName: "receiverAddress2",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "locatorType",
                    fieldName: "receiverLocatorType",
                  },
                ],
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "receiverCompanyCode",
                    },
                    {
                      type: "IN",
                      field: "transactionType",
                      values: ["IS", "SL"],
                    },
                  ],
                },
                linkedFields: [
                  {
                    name: "receiverProjectName",
                    filteredValues: [
                      {
                        filter: { type: "NIN", field: "transactionType", values: ["IS", "CS"] },
                        resolveValue: true,
                        valueFieldName: "projectName",
                      },
                    ],
                    value: "",
                  },
                  {
                    name: "receiverProjectCode",
                    filteredValues: [
                      {
                        filter: { type: "NIN", field: "transactionType", values: ["IS", "CS"] },
                        resolveValue: true,
                        valueFieldName: "projectCode",
                      },
                    ],
                    value: "",
                  },
                ],
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "receiverContractorName",
                label: "Contractor Name",
                type: "text",
                autoFilled: true,
                displayOnly: true,
                allowNull: true,
                props: {
                  width: "17vw",
                },
              },
              {
                name: "receiverContractorCode",
                label: "Contractor",
                type: "text",
                autoFilled: true,
                defaultValue: null,
                allowNull: true,
                props: {
                  width: "7vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "IN",
              field: "transactionType",
              values: ["IS", "SL", "CS", "MS"],
            },
            childFields: [
              {
                name: "receiverProjectType",
                label: "Receiver Project Type",
                type: "picklist",
                values: projectTypeValues,
                autoFilled: true,
                props: {
                  width: "12vw",
                },
                displayOnly: true,
              },
              {
                name: "receiverMaterialType",
                label: "Receiver Material Type",
                type: "picklist",
                values: materialTypeValues,
                allowUpdate: true,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "NIN",
                      field: "transactionType",
                      values: ["IS"],
                    },
                    {
                      type: "MISSING",
                      field: "receiverLocatorCode",
                    },
                  ],
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "IN",
              field: "transactionType",
              values: ["IS", "SL", "CS", "MS"],
            },
            childFields: [
              {
                name: "receiverProjectName",
                label: "Receiver Project Name",
                type: "lookup",
                allowUpdate: true,
                lookupType: "project",
                lookupCategory: "receiverProject",
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "EQUALS",
                      field: "projectCode",
                      resolveValue: true,
                      valueFieldName: "receiverProjectCode",
                    },
                    {
                      type: "EQUALS",
                      field: "projectType",
                      resolveValue: true,
                      valueFieldName: "receiverProjectType",
                    },
                  ],
                },
                lookupParams: [
                  {
                    key: "receiverStateCode",
                    resolveValue: true,
                    valueFieldName: "receiverStateCode",
                  },
                  {
                    key: "locatorCode",
                    resolveValue: true,
                    valueFieldName: "receiverLocatorCode",
                  },
                  {
                    key: "projectType",
                    resolveValue: true,
                    valueFieldName: "receiverProjectType",
                  },
                  {
                    key: "locatorType",
                    resolveValue: true,
                    valueFieldName: "receiverLocatorType",
                  },
                ],
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "receiverProjectCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "virtualLocator",
                    fieldName: "receiverVirtualLocator",
                  },
                ],
                props: {
                  width: "17vw",
                },
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "NIN",
                      field: "transactionType",
                      values: ["IS", "CS"],
                    },
                    {
                      type: "MISSING",
                      field: "receiverLocatorCode",
                    },
                  ],
                },
              },
              {
                name: "receiverProjectCode",
                label: "Receiver Project Code",
                type: "text",
                autoFilled: true,
                props: {
                  width: "7vw",
                },
                displayOnly: true,
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "NIN",
              field: "transactionType",
              values: ["IS", "SL", "CS", "MS"],
            },
            childFields: [
              {
                name: "receiverAddress1",
                label: "Address1 ",
                type: "text",
                autoFilled: true,
                props: {
                  width: "25vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "NIN",
              field: "transactionType",
              values: ["IS", "SL", "CS", "MS"],
            },
            childFields: [
              {
                name: "receiverAddress2",
                label: "Address2 ",
                type: "text",
                allowNull: true,
                autoFilled: true,
                props: {
                  width: "25vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "receiverStationName",
                label: "Receiver Station",
                type: "text",
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "receiverStateName",
                label: "Receiver State/Country",
                type: "text",
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "receiverDocType",
                label: "Receiver Doc Type",
                type: "picklist",
                defaultValue: "NA",
                allowUpdate: true,
                allowNull: false,
                props: {
                  width: "12vw",
                },
                values: receiverDocTypeValues,
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "IN",
                      field: "transactionType",
                      values: ["CS"],
                    },
                    // {
                    //   type: "MISSING",
                    //   field: "receiverLocatorCode",
                    // },
                  ],
                },
                linkedFields: [
                  { name: "receiverDocNo", value: null },
                  { name: "receiverDocDate", value: null },
                ],
              },
              {
                name: "receiverGSTNo",
                label: "Receiver GST No",
                type: "text",
                allowNull: true,
                autoFilled: true,
                displayOnly: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "receiverDocNo",
                label: "Receiver Doc No",
                type: "text",
                allowUpdate: true,
                defaultValue: "",
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "NIN",
                  field: "receiverDocType",
                  values: ["PO", "LO", "MR"],
                },
                visibility: {
                  type: "NIN",
                  field: "receiverDocType",
                  values: ["CO"],
                },
              },
              {
                name: "receiverDocNo",
                label: "Receiver Doc No",
                type: "lookup",
                lookupType: "child-work-order",
                lookupCategory: "inwardChildWorkOrder",
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "EQUALS",
                      field: "contractorCode",
                      resolveValue: true,
                      valueFieldName: "receiverContractorCode",
                    },
                    {
                      type: "EQUALS",
                      field: "projectCode",
                      resolveValue: true,
                      valueFieldName: "receiverProjectCode",
                    },
                  ],
                },
                allowUpdate: true,
                defaultValue: "",
                dependentFields: [
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "cwoDate",
                    fieldName: "receiverDocDate",
                  },
                ],
                props: {
                  width: "12vw",
                },
                visibility: {
                  type: "IN",
                  field: "receiverDocType",
                  values: ["CO"],
                },
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "NIN",
                      field: "receiverDocType",
                      values: ["CO"],
                    },
                    {
                      type: "MISSING",
                      field: "projectCode",
                    },
                    {
                      type: "MISSING",
                      field: "receiverLocatorCode",
                    },
                  ],
                },
              },
              {
                name: "receiverDocDate",
                label: "Receiver Doc Date",
                type: "date",
                constraints: [{ type: "DATE_RANGE", beforeNow: true }],
                allowUpdate: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "IN",
                  field: "receiverDocType",
                  values: ["CO", "NA"],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "details",
    label: "Details",
    allowUpdate: true,
    allowNull: true,
    type: "formTable",
    tableFields: materialOutwardDetailFields,
    disability: {
      type: "OR",
      filters: [
        {
          type: "MISSING",
          field: "projectCode",
        },
        {
          type: "MISSING",
          field: "docLocatorName",
        },
        {
          type: "MISSING",
          field: "materialType",
        },
      ],
    },
    props: {
      width: "82vw",
    },
  },
  {
    name: "sectionB",
    type: "formSection",
    alignment: "row",
    props: {
      width: "25vw",
      spacing: "2vw",
      justify: "start",
    },
    childFields: [
      {
        name: "section1",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
          justify: "start",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            props: {
              width: "25vw",
              justify: "start",
            },
            childFields: [
              {
                name: "requestBy",
                label: "Request By",
                type: "text",
                allowNull: true,
                allowUpdate: true,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "requestRefNo",
                label: "Request Ref No",
                type: "text",
                allowNull: true,
                allowUpdate: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "remarks",
            label: "Remarks",
            type: "text",
            allowUpdate: true,
            allowNull: true,
            props: {
              width: "25vw",
            },
          },
        ],
      },
      {
        name: "section2",
        type: "formSection",
        alignment: "column",
        props: {
          width: "21vw",
          spacing: "0.75vw",
          justify: "top",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "totalValue",
                label: "Total Value",
                autoFilled: true,
                type: "number",
                decimal: true,
                defaultValue: 0,
                props: {
                  width: "21vw",
                },
                valueScript: {
                  operation: "summation",
                  valueFields: ["details"],
                  fieldToSum: "totalAmount",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            props: {
              width: "21vw",
              justify: "center",
            },
            childFields: [
              {
                name: "cancelled",
                label: "Cancelled",
                type: "checkbox",
                props: {
                  width: "21vw",
                  justify: "center",
                },
                defaultValue: false,
                allowUpdate: true,
                disabledForCreation: true,
                disability: {
                  type: "IN",
                  field: "submit",
                  values: [true],
                },
              },
              {
                name: "submit",
                label: "Submit",
                type: "checkbox",
                props: {
                  width: "21vw",
                  justify: "center",
                },
                defaultValue: false,
                allowUpdate: true,
                disability: {
                  type: "IN",
                  field: "cancelled",
                  values: [true],
                },
              },
            ],
          },
        ],
      },
      {
        name: "section2",
        type: "formSection",
        alignment: "column",
        props: {
          width: "21vw",
          spacing: "0.75vw",
          justify: "start",
        },
        childFields: [
          {
            name: "fileInfos",
            label: "File Infos",
            type: "attachment",
            allowUpdate: true,
            allowNull: true,
            props: {
              width: "25vw",
              spacing: "0.75vw",
              justify: "start",
            },
          },
        ],
      },
    ],
  },
];

export const materialOutwardColumns = [
  {
    name: "id",
    label: "MOA No",
    type: "text",
  },
  {
    name: "docDate",
    label: "MOA Date",
    type: "date",
    allowNull: true,
    format: (value) => formatDate(value),
  },
  {
    name: "docLocatorCode",
    label: "Out Locator",
    type: "text",
  },
  {
    name: "docLocatorName",
    label: "Sender Locator Name",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "transactionType",
    label: "Trans Type",
    allowNull: true,
    type: "text",
    format: (value) => {
      const transactionType = outTransactionTypeValues.find((item) => item.value === value);
      return transactionType ? transactionType.label : "Unknown";
    },
  },
  {
    name: "materialType",
    label: "Mat Type",
    allowNull: true,
    type: "text",
    format: (value) => {
      const materialType = materialTypeValues.find((item) => item.value === value);
      return materialType ? materialType.label : "Unknown";
    },
  },
  {
    name: "receiverCompanyName",
    label: "Receiver Company",
    type: "text",
  },
  {
    name: "receiverLocatorName",
    label: "Receiver Locator",
    type: "text",
  },
  {
    name: "totalValue",
    label: "Total Value",
    type: "number",
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "submit",
    label: "Submit",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "gpFlg",
    label: "GP Made",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const materialOutwardSearchColumns = [
  {
    name: "moaId",
    label: "MOA No",
    type: "text",
    defaultValue: null,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "docDate",
    label: "MOA Date",
    type: "date",
    defaultValue: null,
  },
  {
    name: "materialOutwardMst.docLocatorCode",
    label: "Out Locator",
    type: "text",
    defaultValue: null,
  },
  {
    name: "materialOutwardMst.projectCode",
    label: "Project",
    type: "text",
    defaultValue: null,
  },
  {
    name: "transactionType",
    label: "Trans Type",
    type: "picklist",
    defaultValue: null,
    values: outTransactionTypeValues,
  },
  {
    name: "materialType",
    label: "Mat Type",
    type: "picklist",
    defaultValue: null,
    values: materialTypeValues,
  },
  {
    name: "companyName",
    label: "Receiver Company",
    type: "text",
    fEntityType: "receiverCompany",
    defaultValue: null,
  },
  {
    name: "locatorName",
    label: "Receiver Locator",
    type: "text",
    fEntityType: "receiverLocator",
    defaultValue: null,
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "picklist",
    defaultValue: null,
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "submit",
    label: "Submit",
    type: "picklist",
    defaultValue: null,
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "gpFlg",
    label: "GP Made",
    type: "picklist",
    defaultValue: null,
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.8,
    },
  },
];

export const materialOutwardExcelColumns = [
  {
    name: "id",
    label: "MOA No",
    type: "text",
  },
  {
    name: "docDate",
    label: "MOA Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "docLocatorCode",
    label: "Out Locator",
    type: "text",
  },
  {
    name: "docLocatorName",
    label: "Out Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "Out Station",
    type: "text",
  },
  {
    name: "docStateName",
    label: "Out State",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "docCompanyCode",
    label: "Out Company",
    type: "text",
  },
  {
    name: "docCompanyName",
    label: "Sender Company Name",
    type: "text",
  },
  {
    name: "senderDocType",
    label: "Sender Doc Type",
    type: "text",
    format: (value) => senderDocTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "senderDocNo",
    label: "Sender Doc No",
    type: "text",
  },
  {
    name: "senderDocDate",
    label: "Sender Doc Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "transactionType",
    label: "Trans Type",
    type: "text",
    format: (value) => outTransactionTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "materialType",
    label: "Mat Type",
    type: "text",
    format: (value) => materialTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "receiverLocatorCode",
    label: "Receiver Locator",
    type: "text",
  },
  {
    name: "receiverLocatorName",
    label: "Receiver Locator Name",
    type: "text",
  },
  {
    name: "receiverAddress1",
    label: "Address1",
    type: "text",
  },
  {
    name: "receiverAddress2",
    label: "Address2",
    type: "text",
  },
  {
    name: "receiverStationName",
    label: "Receiver Station",
    type: "text",
  },
  {
    name: "receiverStateName",
    label: "State/Country",
    type: "text",
  },
  {
    name: "receiverCompanyCode",
    label: "Receiver Company",
    type: "text",
  },
  {
    name: "receiverCompanyName",
    label: "Receiver Company Name",
    type: "text",
  },
  {
    name: "receiverDocType",
    label: "Receiver Doc Type",
    type: "text",
    format: (value) => receiverDocTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "receiverDocNo",
    label: "Receiver Doc No",
    type: "text",
  },
  {
    name: "receiverDocDate",
    label: "Receiver Doc Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "totalValue",
    label: "Total Value",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "submit",
    label: "Submit",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "gpFlg",
    label: "GP Made",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "gpId",
    label: "GP No",
    type: "number",
  },
  {
    name: "gpDate",
    label: "GP Date",
    type: "date",
    format: (value) => formatDate(value),
  },
];

export const materialOutwardDetailExcelColumns = [
  {
    name: "moaId",
    label: "MOA No",
    type: "text",
  },
  {
    name: "docDate",
    label: "MOA Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "docLocatorCode",
    label: "Out Locator",
    type: "text",
  },
  {
    name: "docLocatorName",
    label: "Sender Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "Out Station",
    type: "text",
  },
  {
    name: "docStateName",
    label: "Out State",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "docCompanyCode",
    label: "Out Company",
    type: "text",
  },
  {
    name: "docCompanyName",
    label: "Sender Company Name",
    type: "text",
  },
  {
    name: "senderDocType",
    label: "Sender Doc Type",
    type: "text",
    format: (value) => senderDocTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "senderDocNo",
    label: "Sender Doc No",
    type: "text",
  },
  {
    name: "senderDocDate",
    label: "Sender Doc Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "transactionType",
    label: "Trans Type",
    type: "text",
    format: (value) => outTransactionTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "materialType",
    label: "Mat Type",
    type: "text",
    format: (value) => materialTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "receiverLocatorCode",
    label: "Receiver Locator",
    type: "text",
  },
  {
    name: "receiverLocatorName",
    label: "Receiver Locator Name",
    type: "text",
  },
  {
    name: "receiverAddress1",
    label: "Address1",
    type: "text",
  },
  {
    name: "receiverAddress2",
    label: "Address2",
    type: "text",
  },
  {
    name: "receiverStationName",
    label: "Receiver Station",
    type: "text",
  },
  {
    name: "receiverStateName",
    label: "State/Country",
    type: "text",
  },
  {
    name: "receiverCompanyCode",
    label: "Receiver Company",
    type: "text",
  },
  {
    name: "receiverCompanyName",
    label: "Receiver Company Name",
    type: "text",
  },
  {
    name: "receiverDocType",
    label: "Receiver Doc Type",
    type: "text",
    format: (value) => receiverDocTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "receiverDocNo",
    label: "Receiver Doc No",
    type: "text",
  },
  {
    name: "receiverDocDate",
    label: "Receiver Doc Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "totalValue",
    label: "Total Value",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "id",
    label: "Row Id",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "Unit",
    type: "text",
  },
  {
    name: "mirRowId",
    label: "MIR Row Id",
    type: "text",
  },
  {
    name: "mirId",
    label: "MIR No",
    type: "text",
  },
  {
    name: "partSerialNo",
    label: "Part Serial",
    type: "text",
  },
  {
    name: "batchNo",
    label: "Batch No",
    type: "text",
  },
  {
    name: "totalQuantity",
    label: "Out Quantity",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "rate",
    label: "Rate",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalAmount",
    label: "Total Amount",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "submit",
    label: "Submit",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "gpFlg",
    label: "GP Made",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

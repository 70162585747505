import axios from "axios";
import { createBrowserHistory } from "history";
import store from "../store";
import { logout } from "../store/actions/auth";

const history = createBrowserHistory();

export function setTokenHeader(token) {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
}

export function apiCall(method, path, data?, requestHeaders?): any {
  return new Promise((resolve, reject) => {
    return axios[method.toLowerCase()](path, data, requestHeaders)
      .then((res: any) => {
        return resolve(res.data);
      })
      .catch((err) => {
        console.log("encountered error", err);
        if (err.response.status === 401) {
          console.log("encountered error inside history");
          store.dispatch(logout());
          history.push("/");
        }
        return reject(err.response.data.error || err.response.data);
      });
  });
}

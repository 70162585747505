import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import ConfirmationButtonComponent from "../buttons/ConfirmationButtonComponent";
import withAuth from "../../hocs/withAuth";
import { FormInputField } from "../inputFields/FormInputField";
import { isValidRecord } from "../../services/utils";
import _ from "lodash";
import { getDefaultValuesForFields, getLeafFields, getFieldNameToFieldMap } from "../../services/fieldsUtils";
import { generateExcelReport, createExcelReportFromRecords } from "../../services/reportGenerationUtils";
import { apiCall } from "../../services/api";
import { Snackbar } from "@mui/material";
import { useSuccessMessage, useSuccessMessageDispatch } from "./SuccessMessageProvider";

const GenerateReportForm = ({
  formMinWidth = "38vw",
  fields,
  getExcelColumns,
  pageTitle,
  getReportName,
  reportType,
  isValidReportRequest = () => true,
  preProcessReportSearchRequest = (searchRequest) => searchRequest,
}) => {
  let defaultValues = getDefaultValuesForFields(fields);
  const [searchRequest, setSearchRequest] = useState(defaultValues);
  const [error, setError] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [snackOpen, setSnackOpen] = useState(null);
  const [snackMessage, setSnackMessage] = useState(null);
  const successMessage = useSuccessMessage(pageTitle);
  const dispatch = useSuccessMessageDispatch();

  const handleClear = () => {
    setSearchRequest(defaultValues);
    setError(null);
    setFormErrors({});
    dispatch({ type: "set", formName: pageTitle, value: null });
  };

  const handleClose = () => {
    setSnackOpen(false);
  };

  const handleSubmit = (searchRequest) => {
    if (!isValidSearchRequest(searchRequest)) {
      return;
    }
    preProcessReportSearchRequest(searchRequest);
    dispatch({
      type: "set",
      formName: pageTitle,
      value: `'${getReportName(searchRequest)}' generation request submitted. It will be downloaded as soon as it is ready`,
    });
    generateExcelReport({
      fetchReportData: generateReport,
      fileName: getReportName(searchRequest),
      searchRequest,
      fieldNameToFieldMap: getFieldNameToFieldMap(fields),
    });
  };

  const generateReport = async () => {
    let localSearchRequest = searchRequest;
    try {
      let response = await apiCall("post", `/api/report/${reportType}/`, localSearchRequest);
      if (!response || response == null || !response.results || response.results.length === 0) {
        setSnackMessage(`No data found for '${getReportName(localSearchRequest)}'`);
        setSnackOpen(true);
        return null;
      }
      setSnackMessage(`'${getReportName(localSearchRequest)}' Generated`);
      setSnackOpen(true);
      return createExcelReportFromRecords(response.results, getExcelColumns(localSearchRequest));
    } catch (e) {
      console.log(`Error occured while fetching report '${getReportName(localSearchRequest)}' with request`, localSearchRequest, e);
      setSnackMessage(`'${getReportName(localSearchRequest)}' failed`);
      setSnackOpen(true);
      return null;
    } finally {
      dispatch({ type: "set", formName: pageTitle, value: null });
    }
  };

  const isValidSearchRequest = (searchRequest) => {
    if (!isValidReportRequest(searchRequest)) {
      return false;
    }
    return isValidRecord({
      record: searchRequest,
      leafFields: getLeafFields(fields),
      setError,
      setFormErrors,
      allowNullFields: true,
    });
  };

  return (
    <div>
      <Box
        sx={{
          marginTop: 1.5,
          minWidth: 100,
          minHeight: "70vh",
          justifyContent: "center",
        }}
      >
        <div className="row justify-content-md-center text-center">
          <div className="col-md-6">
            <form>
              <Stack direction="column" alignItems="center" spacing={2}>
                <h4>{pageTitle}</h4>
                <Paper
                  sx={{
                    minWidth: formMinWidth,
                    minHeight: "80vh",
                    backgroundColor: "#F7F6FF",
                  }}
                  className="flexPaper"
                  elevation={10}
                >
                  <Stack sx={{ mt: 4, mb: 4 }} direction="column" alignItems="center" spacing={2}>
                    {fields.map((field) => (
                      <FormInputField
                        field={field}
                        leafFields={getLeafFields(fields)}
                        record={searchRequest}
                        setRecord={setSearchRequest}
                        formErrors={formErrors}
                        setFormErrors={setFormErrors}
                        setError={setError}
                        globalConstants={searchRequest}
                        formDisabled={successMessage}
                      />
                    ))}
                  </Stack>
                </Paper>

                <Stack direction="row" alignItems="center" spacing={2}>
                  <Button variant="contained" color="error" onClick={handleClear} disabled={!_.isEmpty(successMessage)}>
                    Clear
                  </Button>
                  <ConfirmationButtonComponent
                    handleSubmit={handleSubmit}
                    confirmationTitle="Generate Report"
                    confirmationBody="Are You Sure You Wish To Proceed?"
                    submitBody={searchRequest}
                    buttonText={"Generate"}
                    disabled={!_.isEmpty(successMessage)}
                    shouldOpen={isValidSearchRequest}
                  />
                </Stack>
                <h4> </h4>
                {error && (
                  <div style={{ width: "30rem", justifyContent: "center" }} className="alert alert-danger">
                    {error}
                  </div>
                )}
              </Stack>
            </form>
          </div>
        </div>
        <Snackbar
          open={snackOpen}
          autoHideDuration={3000}
          onClose={handleClose}
          message={snackMessage}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
        {successMessage && <Alert>{successMessage}</Alert>}
      </Box>
    </div>
  );
};

export default withAuth(GenerateReportForm);

import React from "react";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import RecordManager from "./recordStandardForms/RecordManager";

const Homepage = ({ currentUser }) => {
  if (!currentUser.isAuthenticated) {
    return (
      <div className="home-hero">
        <h1>Welcome To Contractor Inventory Management System !</h1>
        <h2> </h2>
        <Stack direction="row" alignItems="center" spacing={3}>
          <Link to="/login" type="button" className="btn btn-primary">
            LOGIN
          </Link>
          <Link to="/signup" type="button" className="btn btn-primary">
            SIGN UP
          </Link>
        </Stack>
      </div>
    );
  }
  return (
    <div>
      <RecordManager entityType="stock" pageTitle="Material Stock" />
    </div>
  );
};

export default Homepage;

import { createContext, useContext, useReducer } from "react";

const SuccessMessageContext = createContext();
const SuccessMessageDispatchContext = createContext(null);

export const useSuccessMessage = (formName) => {
  return useContext(SuccessMessageContext)[formName];
};

export function useSuccessMessageDispatch() {
  return useContext(SuccessMessageDispatchContext);
}

export const SuccessMessageProvider = ({ children }) => {
  const [successMessages, dispatch] = useReducer(successMessageReducer, {});

  return (
    <SuccessMessageContext.Provider value={successMessages}>
      <SuccessMessageDispatchContext.Provider value={dispatch}>{children}</SuccessMessageDispatchContext.Provider>
    </SuccessMessageContext.Provider>
  );

  function successMessageReducer(state, action) {
    switch (action.type) {
      case "set": {
        return { ...state, [action.formName]: action.value };
      }
      case "get": {
        return state[action.formName];
      }
      default: {
        throw new Error("Unknown action type: " + action.type);
      }
    }
  }
};
